import * as Yup from "yup";
import { debounce } from "lodash";

export const debouncedSearchHandler = debounce(
  (dispatch, action, keyName, keyValue, uplinerId) => {
    dispatch(action(keyName, keyValue, uplinerId));
  },
  1000
); // Adjust debounce delay as needed

export const STEP_SIX_SCHEMA = Yup.object().shape({
  direct_partner_me_other: Yup.string().required("Required"),
  direct_partner_other_value: Yup.string().when("direct_partner_me_other", {
    is: "other",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  direct_partner_id: Yup.string().required("Required"),
  upliner_partner_me_other: Yup.string().required("Required"),
  upliner_partner_other_value: Yup.string().when("upliner_partner_me_other", {
    is: "other",
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  upliner_partner_id: Yup.string().required("Required"),
  direction: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character"
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
  shortTerms: Yup.boolean()
    .oneOf([true], "Please you should accept our Terms of use")
    .required("Please you should accept our Terms of use"),
});

export const STEP_SIX_INITIAL_VALUES = {
  direct_partner_me_other: "",
  direct_partner_other_value: "",
  direct_partner_id: "",
  upliner_partner_me_other: "",
  upliner_partner_other_value: "",
  upliner_partner_id: "",
  direction: "",
  password: "",
  password_confirmation: "",
  shortTerms: false,
};
