import { clearLoggedInUser } from "./Redux/Auth/AuthSlice";

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        (config) => {
            const { Auth } = store.getState();
            config.baseURL = process.env.REACT_APP_API_BASEURL;
            config.headers.Accept = '*/*';
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

            if (Object.keys(Auth.loggedInUser).length > 0) {
                config.headers.Authorization = 'Bearer ' + Auth?.loggedInUser?.data?.token;
            }

            return config;
        },
        (err) => Promise.reject(err)
    );
    // Response interceptor for API calls
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            if (error.response.status === 401) {
                store.dispatch(clearLoggedInUser());
            }
            return Promise.reject(error);
        }
    );
} 
