import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { viewPaymentDetailsApi } from "../../apis";
import RequestSection from "../../Components/RequestDetails/RequestSection";
import PaymentDocument from "../../Components/RequestDetails/PaymentDocument";
import RequestHeader from "../../Components/RequestDetails/RequestHeader";

const PaymentDetails = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    viewPaymentDetailsApi(id)
      .then((response) => {
        setProfileData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message || "An error occurred while fetching data");
        setLoading(false);
      });
  }, [id]);

  if (loading) return <div>Loading...</div>;

  if (error) return <div>Error loading profile data: {error}</div>;

  if (!profileData) return <div>No profile data available</div>;

  const { request_details, action_details } = profileData;
  const {
    partner_name,
    partner_phone,
    requested_by_name,
    requested_by_phone,
    request_status,
  } = request_details;
  const partnerDataObj = {
    name: partner_name,
    phone: partner_phone,
  };
  const requestedByDataObj = {
    name: requested_by_name,
    phone: requested_by_phone,
  };
  const {
    transaction_id,
    transaction_number,
    transaction_date,
    transaction_performed_by,
    transaction_category,
    e_money_transferred,
    transaction_notes,
  } = action_details;

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="request-details tw-w-full">
        <RequestHeader requestDetails={request_details} />
        <div className="request-body">
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <RequestSection title="Partner Data" data={partnerDataObj} />
              <RequestSection
                title="Payment Value"
                data={request_details.payment_value}
                unit={"EGP"}
              />
              <RequestSection
                title="Payment Date"
                data={request_details.payment_date}
              />
              <RequestSection
                title="Payment Notes"
                data={request_details.payment_notes || "No notes available"}
              />
            </div>
            <div className="col-md-6">
              <RequestSection title="Requested By" data={requestedByDataObj} />
              <RequestSection
                title="Payment Method"
                data={request_details.payment_method}
              />
              <RequestSection
                title="Payment Transaction No."
                data={request_details.payment_transaction_number}
              />
              <RequestSection
                title="Verified Notes"
                data={request_details.verified_notes || "No notes available"}
              />
            </div>
          </div>

          <PaymentDocument documentUrl={request_details.payment_document} />
        </div>
      </div>
      {request_status === "Accepted" && (
        <div className="request-details tw-w-full">
          <div className="">
            <h2 className="mb-4 !tw-text-red-600">Action Details</h2>
            <div className="request-header">
              <p>
                Transaction No: <span>{transaction_number}</span>
              </p>
              <p>
                Transaction Date:{" "}
                <span>{new Date(transaction_date).toLocaleString()}</span>
              </p>
            </div>
          </div>
          <div className="request-body">
            <div className="row flex-grow-1">
              <div className="col-md-6">
                <RequestSection
                  title="Transaction By"
                  data={transaction_performed_by}
                />
                <RequestSection
                  title="E-Money Transferred"
                  data={e_money_transferred}
                  unit={"EGP"}
                />
              </div>
              <div className="col-md-6">
                <RequestSection
                  title="Transaction Category"
                  data={transaction_category}
                />
                <RequestSection
                  title="Transaction Notes"
                  data={
                    request_details.transaction_notes || "No notes available"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
