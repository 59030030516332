import React from "react";

const TableSection = ({ tableData, title }) => {
  return (
    <section className="tw-container tw-mx-auto tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md">
      <h3 className="tw-text-xl tw-font-semibold tw-text-red-600 tw-mb-4 tw-border-b tw-pb-2">
        {title}
      </h3>
      <div className="tw-overflow-x-auto">
        <table className="tw-min-w-full tw-bg-white tw-rounded-lg tw-shadow-md">
          <thead className="tw-bg-gray-200">
            <tr>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Current Iteration
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Cumulative Iteration
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                PVL
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                PVR
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Points
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Earning e.money
              </th>
            </tr>
          </thead>
          {tableData.length ? (
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className="tw-border-b tw-border-gray-200">
                  <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                    {item.cur_it}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                    {item.acc_it}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                    {item.pvl}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                    {item.pvr}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                    {item.points}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                    {item.e_money} e.m
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <td
                className="tw-px-4 tw-py-2 tw-my-4 text-center tw-text-sm tw-bg-gray-300 tw-rounded-lg "
                colSpan={6}
              >
                No Data Available
              </td>
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default TableSection;
