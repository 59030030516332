import React, { useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import {
  checkWalletBalanceAction,
  getCurrentPartnerStepInfoAction,
  getPaymentMethodsAction,
  saveStepData,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import { useDispatch, useSelector } from "react-redux";
import { setStep3EmoneyFromWalletErrors } from "../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Initial values for the form
const stepOneInitialData = {
  emoney_source: "",

  payment_method: "",
  payment_value: "",
  payment_date: "",
  payment_trans_number: "",
  document: null,
  payment_note: "",
};

const PartnerStepThree = () => {
  const { step1, step3 } = useSelector((state) => state?.AddOrEditPartner);
  const { lang } = useSelector((state) => state?.UI); //ar or en
  const navigate = useNavigate();
  const [requestSent, setRequestSent] = useState(false);
  const dispatch = useDispatch();
  const { stepInfo: Step1Info } = step1;
  const { EmoneyValidation, paymentMethods, message } = step3;

  const StepOneSchema = Yup.object().shape({
    emoney_source: Yup.string().required("Required"),

    payment_method: Yup.string().when("emoney_source", {
      is: "admin_verification",
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    payment_value: Yup.number().when("emoney_source", {
      is: "admin_verification",
      then: (schema) =>
        schema.required("Required").positive("Must be a positive number"),
      otherwise: (schema) => schema.nullable(),
    }),
    payment_date: Yup.date().when("emoney_source", {
      is: "admin_verification",
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),

    payment_trans_number: Yup.string().notRequired(),

    document: Yup.mixed().when("emoney_source", {
      is: "admin_verification",
      then: (schema) =>
        schema
          .required("Required")
          .test("fileType", "Invalid file format", function (value) {
            if (!value) return true; // Handle optional file input
            const allowedExtensions = ["pdf", "png", "jpg", "jpeg"];
            const fileExtension = value?.name?.split(".").pop().toLowerCase();
            return allowedExtensions.includes(fileExtension);
          })
          .test(
            "fileSize",
            "File size must be less than 5MB",
            function (value) {
              if (!value) return true; // Handle optional file input
              const maxSize = 5 * 1024 * 1024; // 5MB in bytes
              return value?.size <= maxSize;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const handleOnChangeEmoney_source = (inputValue) => {
    if (inputValue === "wallet") {
      dispatch(checkWalletBalanceAction(Step1Info.id));
    }
    if (inputValue === "admin_verification") {
      dispatch(setStep3EmoneyFromWalletErrors([]));
      dispatch(getPaymentMethodsAction([]));
    }
  };
  const onSubmitSuccessFuly = (type) => {
    let message =
      "Request has been sent successfully, waiting for admin approval";

    if (type === "wallet") {
      message = "Wallet request has been sent successfully";
    }

    Swal.fire({
      title: "Good!",
      text: message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        if (type === "wallet") {
          navigate(0);
        } else {
          navigate("/partners-list");
        }
      }
    });
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("client_id", Step1Info.id);
    formData.append("emoney_source", values.emoney_source);

    if (values.emoney_source === "wallet") {
      if (!(EmoneyValidation?.length > 0)) {
        dispatch(saveStepData(formData, 3, true, () => {}));
      }
      dispatch(getCurrentPartnerStepInfoAction(Step1Info.id, () => {}));

      // dispatch(checkWalletBalanceAction(Step1Info.id))
    } else if (values.emoney_source === "admin_verification") {
      formData.append(
        "admin_verification[payment_method]",
        values.payment_method
      );
      formData.append(
        "admin_verification[payment_value]",
        values.payment_value
      );
      if (!(values.payment_trans_number == "")) {
        formData.append(
          "admin_verification[payment_trans_number]",
          values.payment_trans_number
        );
      }
      formData.append("admin_verification[payment_date]", values.payment_date); // format to YYYY-MM-DD
      formData.append("admin_verification[document]", values.document);
      if (!(values.payment_note.replace(/\s+/g, "").length === 0)) {
        formData.append(
          "admin_verification[payment_note]",
          values.payment_note
        );
      }

      dispatch(saveStepData(formData, 3, true, () => {}));
      dispatch(
        getCurrentPartnerStepInfoAction(
          Step1Info.id,
          () => {},
          () => {
            Navigate("/partners-list");
          }
        )
      );
    }
    setRequestSent(true);
    onSubmitSuccessFuly(values.emoney_source);
  };

  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h4 className="card-title mb-0">Step (3) : Verify e money</h4>
        <h5 className="card-title mb-0">
          Partner Name:{" "}
          <span className="text-muted">
            {Step1Info?.first_name +
              " " +
              Step1Info?.second_name +
              " " +
              Step1Info?.last_name}
          </span>
        </h5>
      </div>
      <div className="card-body">
        {message ||
          (Step1Info && (
            <>
              {Step1Info?.is_payment_verification_rejected ? (
                <div className="alert alert-danger tw-flex tw-gap-4">
                  <h1 className="message !tw-mb-0">
                    Payment Request is rejected by Admin
                  </h1>
                  {Step1Info?.rejection_note && (
                    <h3 className="!tw-mb-0">
                      Reason {Step1Info?.rejection_note}
                    </h3>
                  )}
                </div>
              ) : Step1Info?.is_pending_for_admin_verification ? (
                <div className="alert alert-warning">
                  <h1 className="message !tw-mb-0">
                    Waiting For Admin Verification
                  </h1>
                </div>
              ) : null}
            </>
          ))}
        {requestSent === false &&
          !Step1Info?.is_pending_for_admin_verification && (
            <>
              <Formik
                initialValues={stepOneInitialData}
                validationSchema={StepOneSchema}
                enableReinitialize // To reinitialize stepOneInitialData >> if exist
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form className="flex">
                    <div className="row">
                      {/* Radio Buttons for Money Source */}
                      <div className="col-md-6 col-12 mb-3 mx-auto">
                        <label className="form-label required-label">
                          Money Source
                        </label>

                        <div className="w-100 d-flex align-items-center flex-column">
                          <div className="d-flex" style={{ gap: 10 }}>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="emoney_source"
                                value="wallet"
                                id="wallet"
                                checked={values.emoney_source === "wallet"}
                                onChange={(e) => {
                                  setFieldValue(
                                    "emoney_source",
                                    e.target.value
                                  );
                                  handleOnChangeEmoney_source(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="wallet"
                                className="custom-control-label"
                              >
                                From Your Wallet
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="emoney_source"
                                value="admin_verification"
                                id="admin_verification"
                                checked={
                                  values.emoney_source === "admin_verification"
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    "emoney_source",
                                    e.target.value
                                  );
                                  handleOnChangeEmoney_source(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="admin_verification"
                                className="custom-control-label"
                              >
                                Payment Verification
                              </label>
                            </div>
                          </div>
                          <ErrorMessage
                            name="emoney_source"
                            component="div"
                            className="invalid-feedback"
                          />
                          {touched.emoney_source && (
                            <>
                              {EmoneyValidation?.length > 0 ? (
                                <>
                                  {EmoneyValidation.map((error, errorIndex) => (
                                    <div
                                      className="invalid-feedback"
                                      key={errorIndex}
                                    >
                                      {error}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {errors.otp && (
                                    <div className="invalid-feedback">
                                      {errors.otp}
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Conditionally Rendered Fields //admin_verification */}
                    <div className="row">
                      {values.emoney_source === "admin_verification" && (
                        <>
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label required-label"
                              htmlFor="payment_method"
                            >
                              Payment Method
                            </label>
                            <Field
                              as="select"
                              name="payment_method"
                              className={`custom-control custom-select form-control ${
                                touched.payment_method
                                  ? errors.payment_method
                                    ? "custom-invalid-input"
                                    : ""
                                  : ""
                              }`}
                            >
                              <option value="">Select</option>
                              {paymentMethods?.length > 0 &&
                                paymentMethods.map((pm, pmIndex) => (
                                  <option key={pm.id} value={pm.id}>
                                    {pm[`method_name_${lang}`]}
                                  </option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="payment_method"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label required-label"
                              htmlFor="payment_value"
                            >
                              Payment Value (EGP)
                            </label>
                            <Field
                              type="text"
                              value={values?.payment_value}
                              onChange={(e) => {
                                const { name, value } = e?.target;
                                if (/^\d{0,5}$/.test(value)) {
                                  setFieldValue(name, value);
                                }
                              }}
                              name="payment_value"
                              className={`form-control hidden-controls-number ${
                                touched.payment_value
                                  ? errors.payment_value
                                    ? "custom-invalid-input"
                                    : ""
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="payment_value"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <label
                              className="form-label required-label mr-auto"
                              htmlFor="payment_date"
                            >
                              Payment Actual Date
                            </label>

                            {/* <DatePicker
                                                        selected={values.payment_date}
                                                        onChange={(date) => setFieldValue('payment_date', date)}
                                                        dateFormat="yyyy/MM/dd"
                                                        className={`form-control ${touched.payment_method ? (errors.payment_date ? 'custom-invalid-input' : '') : ''}`}
                                                        onKeyDown={e => e.preventDefault()}  // Disable keyboard input
                                                        onFocus={(e) => e.target.blur()}  // Disable focus
                                                    /> */}
                            <Flatpickr
                              value={values.payment_date}
                              onChange={([date]) => {
                                if (date != null) {
                                  const formattedDate = format(
                                    date,
                                    "yyyy-MM-dd"
                                  );
                                  setFieldValue("payment_date", formattedDate);
                                } else {
                                  setFieldValue("payment_date", "");
                                }
                              }}
                              onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                              onFocus={(e) => e.target.blur()} // Disable focus
                              dateFormat="yyyy-MM-dd"
                              options={{ maxDate: new Date() }}
                              className={`form-control ${
                                touched.payment_method
                                  ? errors.payment_date
                                    ? "custom-invalid-input"
                                    : ""
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="payment_date"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="payment_trans_number"
                            >
                              Payment Transaction Number (Optional)
                            </label>
                            <Field
                              type="number"
                              name="payment_trans_number"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="payment_trans_number"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label required-label"
                              htmlFor="document"
                            >
                              receipt image
                            </label>
                            <div className="">
                              <input
                                type="file"
                                name="document"
                                className={`form-control ${
                                  touched.document
                                    ? errors.document
                                      ? "custom-invalid-input"
                                      : ""
                                    : ""
                                }`}
                                onChange={(event) => {
                                  const file = event.target.files[0];
                                  setFieldValue("document", file);
                                }}
                                accept=".pdf, .png, .jpg, .jpeg"
                              />
                            </div>

                            <ErrorMessage
                              name="document"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/*  payment_note */}
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="payment_note"
                            >
                              payment note
                            </label>
                            <textarea
                              type="text"
                              name="payment_note"
                              className="form-control"
                              rows="4"
                              value={values.payment_note}
                              onChange={(e) => {
                                setFieldValue("payment_note", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="payment_note"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <ErrorMessage
                      name="apiError"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
      </div>
    </>
  );
};

export default PartnerStepThree;
