import { createSlice } from "@reduxjs/toolkit";
import { absoluteUrl } from "../../_helper";

const initialState = {
  isLoading: false,
  error: "",

  MyCourses: [],

  singleCourseInfo: {
    // courseId: 1,
    // courseName: 'MVC Design Pattern',
    // videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
    // courseDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa fugiat ullam dignissimos iste odit repellat itaque placeat, mollitia modi alias.',
    // videosList: [
    //   { id: '1', lessonName: 'Installation', duration: '2:03' },
    //   { id: '2', lessonName: 'Installation', duration: '2:03' },
    //   { id: '3', lessonName: 'Installation', duration: '2:03' }
    // ],
    // instructorName: 'Adrian Demian',
    // instructorImage: absoluteUrl("assets/images/people/110/guy-6.jpg"),
    // instructorTitle: 'Instructor',
    // instructorDescription: 'Having over 12 years exp. Adrian is one of the lead UI designers in the industry Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere, aut.',
    // HoursDuration: 2,
    // minsDuration: 50,
    // courseLevel: 'beginners',
    // courseRating: 2.5,
    // numberOfRaters: 100
  },

  singleLessonInfo: {
    // lessonTitle: 'Introduction',
    // lessonDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus, facere.',
    // videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
    // lessonFilesLink: '#',
    // HoursDuration: 2,
    // minsDuration: 50,
    // courseLevel: 'beginners',

  }
};

export const PartnersCoursesSlice = createSlice({
  name: "partnerCourses",
  initialState,
  reducers: {

    genericSetData: (state, action) => {
      const { keyName, keyValue } = action.payload;
      state[keyName] = keyValue
    },

    // setMyCourses: (state, action) => {
    //   state.MyCourses = action.payload.data;
    // },

  },
});

export const { genericSetData } = PartnersCoursesSlice.actions;
export default PartnersCoursesSlice.reducer;

