import React, { useMemo, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import CustomPagination from "../../../Components/CustomPagination";
import { DATATABLE_CUSTOM_STYLES } from "../../../constants/datatableStyles";
import { createColumns } from "../../../helpers";
import { announcementsListApi } from "../../../apis";
import Swal from "sweetalert2"; // Import Swal

const AnnouncementsList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [requestsData, setRequestsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );
  const [sortKey, setSortKey] = useState(
    new URLSearchParams(location.search).get("sortKey") || ""
  );
  const [sortDirection, setSortDirection] = useState(
    new URLSearchParams(location.search).get("sortDirection") || ""
  );

  const handleSortChange = (column, direction) => {
    setSortKey(column.sortKey);
    setSortDirection(direction);
    setCurrentPage(1); // Reset to first page on sort change
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setIsLoading(true);
      try {
        const response = await announcementsListApi(
          currentPage,
          sortKey,
          sortDirection
        );
        const { data, ...paginator } = response.data.data;
        setRequestsData(data || []); // Set the requests data or empty array if no data
        setPagination(paginator || {}); // Set the pagination data or empty object if no paginator
      } catch (error) {
        const errorMessage = error.response?.data?.message?.[0] || "";

        // Log error in development mode for debugging
        if (process.env.NODE_ENV === "development") {
          console.error("API call failed:", error);
        }

        // Do not show alert if the message is "Column Not Found To Sort By"
        if (errorMessage !== "Column Not Found To Sort By") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to load announcements. Please try again later.",
            confirmButtonText: "Okay",
          });
        }
      } finally {
        setIsLoading(false); // Always hide the loading spinner
      }
    };

    fetchAnnouncements();
  }, [sortKey, sortDirection, currentPage]); // Dependencies for useEffect

  // Effect to update the URL parameters when state changes
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", currentPage);
    if (sortKey) params.set("sortKey", sortKey);
    if (sortDirection) params.set("sortDirection", sortDirection);
    navigate({ search: params.toString() });
  }, [sortDirection, sortKey, currentPage, navigate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const hiddenColumns = ["id", "client_id"];

  const customColumns = [
    {
      name: "Action",
      selector: (row) => "Announcement - " + row.id,
      ui: (row) => {
        return (
          <div className="d-flex gap-2">
            <Link
              to={`/announcements/${row.id}`}
              className="tw-text-primary tw-font-semibold"
            >
              Details
            </Link>
            <div className="">|</div>
            <Link
              to={`/announcements/edit/${row.id}`}
              className="ml-2 tw-text-primary tw-font-semibold"
            >
              Edit
            </Link>
          </div>
        );
      },
    },
  ];

  const keyTransformer = (key) => key.replace(/^announcement_/, "");

  // Define the transformed keys that should be sortable
  const sortableKeys = ["schedule_date", "on_portal"];

  const columns = useMemo(() => {
    return createColumns(
      requestsData,
      customColumns,
      hiddenColumns,
      false,
      keyTransformer
    ).map((col) => {
      const isSortable = sortableKeys.includes(col.transformedKey);
      return {
        ...col,
        sortable: isSortable,
      };
    });
  }, [requestsData]);

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Announcements List</h4>
        <div className="primary-btn">
          <Link
            to={"/announcements/create"}
            className="text-white text-decoration-none"
          >
            New Announcement
          </Link>
        </div>
      </div>

      <div className="bg-white flex flex-col mt-4">
        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <DataTable
            columns={columns}
            data={requestsData}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
            onSort={handleSortChange}
            sortServer
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={pagination?.last_page}
              currentPage={pagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementsList;
