import * as requestFromServer from "./PartnersCrud";
import { PartnersSlice } from "./PartnersSlice";
import { UISlice } from "../UISlice";

const { actions: PartnersActions } = PartnersSlice;
const { actions: UIActions } = UISlice;

export const fetchPartners =
  (type, searchTerm = "", page = 1, sortBy = "", sortDir = "asc") =>
  (dispatch) => {
    dispatch(UIActions.openLoader());

    let fetchFunction;
    switch (type) {
      case "uncompleted":
        fetchFunction = requestFromServer.fetchUncompletedPartners;
        break;
      case "pending":
        fetchFunction = requestFromServer.fetchPendingPartners;
        break;
      case "pv-active":
        fetchFunction = requestFromServer.fetchPvActivePartners;
        break;
      case "direct":
        fetchFunction = requestFromServer.fetchDirectPartners;
        break;
      case "upliner":
        fetchFunction = requestFromServer.fetchUplinerPartners;
        break;
      case "all":
      default:
        fetchFunction = requestFromServer.fetchAllPartners;
        break;
    }

    return fetchFunction(searchTerm, page, sortBy, sortDir)
      .then((response) => {
        if (response.data.status) {
          dispatch(
            PartnersActions.setPartnersData({
              data: response.data.data,
              paginator: response.data.paginator,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(PartnersActions.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };
