import axios from "axios";

const newClientURL = "new/client/";

export function getCurrentPartnerStepInfo(clientId) {
  return axios.get(`${newClientURL}my-client/${clientId}`);
}

export function postStepData(stepData, stepNumber, isMultipart = false) {
  const config = {};

  if (isMultipart) {
    config.headers = {
      "Content-Type": "multipart/form-data",
    };

    return axios.post(`${newClientURL}step${stepNumber}`, stepData, config);
  }

  return axios.post(`${newClientURL}step${stepNumber}`, stepData);
}

export function postUpdateStepData(stepData, stepNumber, isMultipart = false) {
  const config = {};

  if (isMultipart) {
    config.headers = {
      "Content-Type": "multipart/form-data",
    };

    return axios.post(`${newClientURL}step1-update`, stepData, config);
  }

  return axios.post(`${newClientURL}step1-update`, stepData);
}

// step2
export function resendOtp(clientId) {
  return axios.get(`${newClientURL}resend-otp/${clientId}`);
}
// step3
export function checkWalletBalance(clientId) {
  return axios.post(`${newClientURL}step3-wallet`, {
    client_id: clientId,
  });
}

export function getPaymentMethods() {
  return axios.get(`${newClientURL}payment_methods`);
}
// step4
export function getPackages() {
  return axios.get(`${newClientURL}packages`);
}

export function getMyWalletBalance() {
  return axios.get(`${newClientURL}my-wallet/registration`);
}

// step5
export function getEducationalLevel() {
  return axios.get(`${newClientURL}educations`);
}

export function getJobCategories() {
  return axios.get(`${newClientURL}jobs`);
}

export function getCountries() {
  return axios.get(`${newClientURL}countries`);
}

// step6
export function getPartnersBySearch(searchKey) {
  return axios.get(`client/tree/search?val=${searchKey}`);
}

export function getDirectPartnersBySearch(uplinerId, searchKey) {
  return axios.get(
    `client/tree/upliner/search?upliner_id=${uplinerId}&val=${searchKey}`
  );
}

export function checkUplinerPartnerHasLeftOrRight(uplinerPartnerID) {
  return axios.get(
    `client/tree/Upliner-available-children/${uplinerPartnerID}`
  );
}
