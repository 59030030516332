import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AnnouncementSlider from "../../Components/AnnouncementSlider";
import AchievementCard from "../../Components/AchievementCard";
import { USER_TYPES } from "../../constants/globals";
import { adminIndicatorsApi, partnerAchievementsApi } from "../../apis";

const Dashboard = () => {
  const { loggedInUser } = useSelector((state) => state?.Auth);
  const currentUserType = loggedInUser?.data?.model?.user_type_id;
  const [indicators, setIndicators] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [announcements, setAnnouncements] = useState({});

  const fetchIndicators = async () => {
    try {
      const response = await adminIndicatorsApi();
      if (response.data.status) {
        setIndicators(Object.values(response.data.data || {}));
      } else {
        console.error("Failed to fetch indicators:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching indicators:", error);
    }
  };
  const fetchAchievements = async () => {
    try {
      const response = await partnerAchievementsApi();
      if (response.data.status) {
        const { announcements, ...otherData } = response.data.data || {};
        setAnnouncements(announcements);
        setAchievements(Object.values(otherData));
      } else {
        console.error("Failed to fetch indicators:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching indicators:", error);
    }
  };
  useEffect(() => {
    if (
      currentUserType === USER_TYPES.ADMIN ||
      currentUserType === USER_TYPES.MODERATOR
    ) {
      fetchIndicators();
    }
    if (currentUserType === USER_TYPES.PARTNER) {
      fetchAchievements();
    }
  }, [currentUserType]);

  const borderStyles = [
    "tw-border-red-400",
    "tw-border-blue-400",
    "tw-border-yellow-400",
    "tw-border-green-400",
  ];

  // Calculate custom classes for "Total Registration Wallet"
  const totalRegistrationWallet =
    indicators.find(
      (indicator) => indicator.title === "Total Registration Wallet"
    )?.value || 0;
  const eMoneyCreation =
    indicators.find((indicator) => indicator.title === "E-Money Creation")
      ?.value || 0;
  const eMoneyUsed =
    indicators.find((indicator) => indicator.title === "E-Money Used")?.value ||
    0;
  const calculateCustomValueClasses = (indicator) => {
    if (indicator.title === "Total Registration Wallet") {

      // return totalRegistrationWallet !== eMoneyCreation - +eMoneyUsed
      //   ? "tw-text-red-500 tw-font-bold"
      //   : "tw-text-green-500 tw-font-bold";
    }
    return "";
  };

  return (
    <div className="tw-mb-5">
      {(currentUserType === USER_TYPES.ADMIN ||
        currentUserType === USER_TYPES.MODERATOR) &&
        currentUserType !== USER_TYPES.PARTNER && (
          <>
            <h2 className="h4 mt-3 tw-mb-5">Latest Indicators</h2>
            <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-4">
              {!!indicators.length ? (
                indicators.map((indicator, index) => (
                  <AchievementCard
                    key={index}
                    title={indicator.title}
                    count={indicator.value}
                    unit={indicator.unit}
                    borderClasses={borderStyles[index % 4]}
                    customValueClasses={calculateCustomValueClasses(indicator)}
                  />
                ))
              ) : (
                <p className="!tw-mt-4">No indicators found.</p>
              )}
            </div>
          </>
        )}

      {currentUserType === USER_TYPES.PARTNER && (
        <>
          <h2 className="h4 mt-3 tw-mb-5">Join us on</h2>

          <div className="d-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-6 tw-mb-5">
            <div
              className={`d-flex w-100 align-items-stretch justify-content-center tw-h-[120px] `}
            >
              <a
                href="https://t.me/+KxXXt37dzR9mYmM0"
                target="_blank"
                className={`tw-bg-white tw-shadow-md w-100 tw-py-4 tw-px-2 tw-border-l-4 tw-border-red-900`}
              >
                <img
                  src="/imgs/unite_signals.png"
                  className="tw-size-full tw-object-contain"
                  alt=""
                />
              </a>
            </div>
            <div
              className={`d-flex w-100 align-items-stretch justify-content-center tw-h-[120px] `}
            >
              <a
                href="https://t.me/+LFWFACdokfM0Njdk"
                target="_blank"
                className={`tw-bg-white tw-shadow-md w-100 tw-py-4 tw-px-2 tw-border-l-4 tw-border-primary`}
              >
                <img
                  src="/imgs/orginal-logo.png"
                  className="tw-size-full tw-object-contain"
                  alt=""
                />
              </a>
            </div>
            <div
              className={`d-flex w-100 align-items-stretch justify-content-center tw-h-[120px] `}
            >
              <a
                href="https://t.me/+aKzt6djdKW1kOTI0"
                target="_blank"
                className={`tw-bg-white tw-shadow-md w-100 tw-py-4 tw-px-2 tw-border-l-4 tw-border-yellow-400`}
              >
                <img
                  src="/imgs/unite_black.png"
                  className="tw-size-full tw-object-contain"
                  alt=""
                />
              </a>
            </div>
          </div>
          <h2 className="h4">Announcements</h2>
          {!!announcements?.data?.length ? (
            <AnnouncementSlider announcements={announcements.data} />
          ) : (
            <p>No announcements found.</p>
          )}

          <h2 className="h4 mt-3 tw-mb-5">My Achievements</h2>
          <div className="tw-mt-4 row row-cols-2 row-cols-lg-4 g-2 g-lg-4">
            {!!achievements.length ? (
              achievements.map((achievement, index) => (
                <AchievementCard
                  key={index}
                  title={achievement.title}
                  count={achievement.value}
                  unit={achievement.unit}
                  borderClasses={borderStyles[index % 4]}
                />
              ))
            ) : (
              <p>No achievements found.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
