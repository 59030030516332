import React from "react";
import { Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import AllMatchedPartners from "./AllMatchedPartners";
import { genericStepDataByKey } from "../../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import { checkUplinerPartnerHasLeftOrRightAction } from "../../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";

const UplinerPartnerField = ({
  values,
  handleBlur,
  setFieldValue,
  debouncedAsyncValidation,
  touched,
  errors,
}) => {
  const dispatch = useDispatch();
  const {
    allUplinerPartnersMatchsSearchKey,
    showUplinerOtherList,
    leftOrRightUplinerPartner,
  } = useSelector((state) => state?.AddOrEditPartner?.step6);

  const loggedInUserClientId = useSelector(
    (state) => state?.Auth?.loggedInUser?.data?.model?.id
  );

  return (
    <>
      <div className="row">
        <div className="col-md-5 mb-3">
          <label
            className="form-label required-label"
            htmlFor="upliner_partner_me_other"
          >
            Upliner Partner
          </label>
          <Field
            as="select"
            className={`custom-control custom-select form-control ${
              touched.upliner_partner_me_other &&
              errors.upliner_partner_me_other
                ? "custom-invalid-input"
                : ""
            }`}
            name="upliner_partner_me_other"
            onBlur={handleBlur}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("upliner_partner_me_other", value);
              setFieldValue("upliner_partner_other_value", "");
              setFieldValue("direction", "");

              // when changing upliner clears the direct
              setFieldValue("direct_partner_me_other", "");
              setFieldValue("direct_partner_other_value", "");
              setFieldValue("direct_partner_id", "");

              dispatch(
                genericStepDataByKey({
                  keyName: "showUplinerOtherList",
                  keyValue: false,
                  stepNumber: 6,
                })
              );

              if (value !== "other") {
                setFieldValue("upliner_partner_id", value);
                dispatch(
                  checkUplinerPartnerHasLeftOrRightAction(
                    "leftOrRightUplinerPartner",
                    value
                  )
                );
              } else {
                setFieldValue("upliner_partner_id", "");
                dispatch(
                  genericStepDataByKey({
                    keyName: "leftOrRightUplinerPartner",
                    keyValue: [],
                    stepNumber: 6,
                  })
                );
              }
            }}
          >
            <option value="" disabled></option>
            <option value={loggedInUserClientId}>Me</option>
            <option value="other">Other</option>
          </Field>
          {touched.upliner_partner_me_other &&
            errors.upliner_partner_me_other && (
              <div className="invalid-feedback">
                {errors.upliner_partner_me_other}
              </div>
            )}
        </div>

        {values?.upliner_partner_me_other === "other" && (
          <div className="col-md-5 mb-3">
            <div className="w-100 position-relative">
              <label
                className="form-label required-label"
                htmlFor="upliner_partner_other_value"
              >
                Other
              </label>
              <input
                type="text"
                className={`form-control ${
                  touched.upliner_partner_other_value &&
                  errors.upliner_partner_other_value
                    ? "custom-invalid-input"
                    : ""
                }`}
                name="upliner_partner_other_value"
                placeholder="search by referral code or partner name"
                onBlur={handleBlur}
                value={values.upliner_partner_other_value}
                onChange={(e) => {
                  setFieldValue("upliner_partner_other_value", e.target.value);
                  if (e.target.value.length >= 2) {
                    debouncedAsyncValidation(
                      "allUplinerPartnersMatchsSearchKey",
                      e.target.value
                    );
                    dispatch(
                      genericStepDataByKey({
                        keyName: "showUplinerOtherList",
                        keyValue: true,
                        stepNumber: 6,
                      })
                    );
                  } else {
                    dispatch(
                      genericStepDataByKey({
                        keyName: "showUplinerOtherList",
                        keyValue: false,
                        stepNumber: 6,
                      })
                    );
                    dispatch(
                      genericStepDataByKey({
                        keyName: "allUplinerPartnersMatchsSearchKey",
                        keyValue: [],
                        stepNumber: 6,
                      })
                    );
                    setFieldValue("direct_partner_me_other", "");
                    setFieldValue("direct_partner_other_value", "");
                    setFieldValue("direct_partner_id", "");
                  }
                }}
              />
              {allUplinerPartnersMatchsSearchKey.length > 0 &&
                showUplinerOtherList && (
                  <AllMatchedPartners
                    matchedPartners={allUplinerPartnersMatchsSearchKey}
                    onClickHandler={(mp) => {
                      dispatch(
                        genericStepDataByKey({
                          keyName: "showUplinerOtherList",
                          keyValue: false,
                          stepNumber: 6,
                        })
                      );
                      dispatch(
                        genericStepDataByKey({
                          keyName: "allUplinerPartnersMatchsSearchKey",
                          keyValue: [],
                          stepNumber: 6,
                        })
                      );
                      setFieldValue(
                        "upliner_partner_other_value",
                        mp?.referral_code
                      );
                      setFieldValue("upliner_partner_id", mp?.id);
                      dispatch(
                        checkUplinerPartnerHasLeftOrRightAction(
                          "leftOrRightUplinerPartner",
                          mp?.id
                        )
                      );
                      setFieldValue("direction", "");
                    }}
                  />
                )}
            </div>
            {touched.upliner_partner_other_value &&
              errors.upliner_partner_other_value && (
                <div className="invalid-feedback">
                  {errors.upliner_partner_other_value}
                </div>
              )}
          </div>
        )}
        {values?.upliner_partner_me_other &&
          values.direct_partner_id &&
          touched.direct_partner_id &&
          errors.direct_partner_id && (
            <div className="invalid-feedback">{errors.direct_partner_id}</div>
          )}
      </div>

      {values.upliner_partner_id && (
        <div className="row">
          <div className="col-md-5 mb-3">
            <label className="form-label required-label" htmlFor="direction">
              Direction
            </label>
            <Field
              as="select"
              className={`custom-control custom-select form-control ${
                touched.direction && errors.direction
                  ? "custom-invalid-input"
                  : ""
              }`}
              name="direction"
              onBlur={handleBlur}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue("direction", value);
              }}
            >
              <option value="" disabled></option>
              {leftOrRightUplinerPartner?.length === 0 && (
                <option value="" disabled>
                  No available direction please change your Upliner Partner
                </option>
              )}
              {leftOrRightUplinerPartner?.length > 0 &&
                leftOrRightUplinerPartner?.map((el, elIndex) => (
                  <option key={elIndex} value={el}>
                    {el}
                  </option>
                ))}
            </Field>
            {touched.direction && errors.direction && (
              <div className="invalid-feedback"> {errors.direction}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UplinerPartnerField;
