import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import { getPaymentMethods } from "../../Redux/AddOrEditPartner/AddOrEditPartnerCrud";
import { useSelector } from "react-redux";
import EMoneyRequestsHistory from "./EMoneyRequestsHistory";

const initialValues = {
  payment_method: "",
  payment_value: "",
  payment_date: "",
  payment_trans_number: "",
  document: null,
  payment_note: "",
};

const validationSchema = Yup.object().shape({
  payment_method: Yup.string().required("Required"),
  payment_value: Yup.number()
    .required("Required")
    .positive("Must be a positive number"),
  payment_date: Yup.date().required("Required"),
  payment_trans_number: Yup.string().notRequired(),
  document: Yup.mixed()
    .required("Required")
    .test("fileType", "Invalid file format", (value) => {
      if (!value) return true;
      const allowedExtensions = ["pdf", "png", "jpg", "jpeg"];
      const fileExtension = value?.name?.split(".").pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    })
    .test("fileSize", "File size must be less than 5MB", (value) => {
      if (!value) return true;
      const maxSize = 5 * 1024 * 1024;
      return value?.size <= maxSize;
    }),
  payment_note: Yup.string().notRequired(),
});

const RequestEMoney = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { loggedInUser } = useSelector((state) => state.Auth);
  const userData = loggedInUser?.data?.model;
  const [partnerName, setPartnerName] = useState(
    `${userData.first_name} ${userData.second_name}`
  );

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await getPaymentMethods();
        setPaymentMethods(response.data.data);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append("payment_method", values.payment_method);
    formData.append("payment_value", values.payment_value);
    formData.append("payment_date", values.payment_date);
    if (values.payment_trans_number) {
      formData.append("payment_trans_number", values.payment_trans_number);
    }
    formData.append("document", values.document);
    if (values.payment_note) {
      formData.append("payment_note", values.payment_note);
    }

    try {
      await axios.post("/client/request/emoney", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire({
        title: "Success",
        text: "The request has been sent successfully",
        icon: "success",
      });
      resetForm();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "There was an error sending the request",
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="tw-flex-col gap-6">
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-0">Request E-Money</h4>
          <h5 className="card-title mb-0">
            Partner Name: <span className="text-muted">{partnerName}</span>
          </h5>
        </div>
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="payment_method"
                    >
                      Payment Method
                    </label>
                    <Field
                      as="select"
                      name="payment_method"
                      className={`custom-control custom-select form-control ${
                        touched.payment_method
                          ? errors.payment_method
                            ? "custom-invalid-input"
                            : ""
                          : ""
                      }`}
                    >
                      <option value="">Select</option>
                      {paymentMethods?.map((pm, pmIndex) => (
                        <option key={pm.id} value={pm.id}>
                          {pm.method_name_en}
                        </option> // Assuming 'method_name_en' is the field for the method name
                      ))}
                    </Field>
                    <ErrorMessage
                      name="payment_method"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="payment_value"
                    >
                      Payment Value (EGP)
                    </label>
                    <Field
                      type="text"
                      name="payment_value"
                      className={`form-control ${
                        touched.payment_value
                          ? errors.payment_value
                            ? "custom-invalid-input"
                            : ""
                          : ""
                      }`}
                      value={values.payment_value}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        if (/^\d{0,5}$/.test(value)) {
                          setFieldValue(name, value);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="payment_value"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="payment_date"
                    >
                      Payment Date
                    </label>
                    <Flatpickr
                      value={values.payment_date}
                      onChange={([date]) => {
                        if (date) {
                          setFieldValue(
                            "payment_date",
                            format(date, "yyyy-MM-dd")
                          );
                        } else {
                          setFieldValue("payment_date", "");
                        }
                      }}
                      onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                      className={`form-control ${
                        touched.payment_date
                          ? errors.payment_date
                            ? "custom-invalid-input"
                            : ""
                          : ""
                      }`}
                      options={{ maxDate: new Date() }}
                    />
                    <ErrorMessage
                      name="payment_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label"
                      htmlFor="payment_trans_number"
                    >
                      Payment Transaction Number (Optional)
                    </label>
                    <Field
                      type="text"
                      name="payment_trans_number"
                      className="form-control"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        if (/^\d{0,5}$/.test(value)) {
                          setFieldValue(name, value);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="payment_trans_number"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="document"
                    >
                      Receipt Image
                    </label>
                    <input
                      type="file"
                      name="document"
                      className={`form-control ${
                        touched.document
                          ? errors.document
                            ? "custom-invalid-input"
                            : ""
                          : ""
                      }`}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setFieldValue("document", file);
                      }}
                      accept=".pdf, .png, .jpg, .jpeg"
                    />
                    <ErrorMessage
                      name="document"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label" htmlFor="payment_note">
                      Payment Note
                    </label>
                    <Field
                      as="textarea"
                      name="payment_note"
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage
                      name="payment_note"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-0">E-Money Requests History</h4>
        </div>
        <div className="card-body">
          <EMoneyRequestsHistory />
        </div>
      </div>
    </div>
  );
};

export default RequestEMoney;
