import React from "react";
import { Link } from "react-router-dom";
import { renderStarIcon } from "../../../../_helper";
import { useSelector } from "react-redux";
import moment from "moment/moment";
const SingleMyCourseCard = ({ Course, ...props }) => {
  let {
    course_id,
    client_id,
    course_name_en,
    course_name_ar,
    course_image,
    course_level,
    course_duration,
    course_rating,
    course_lessons,
    client_progress,
    client_progress_percentage,
    selection_date

  } = Course || {};


  const { lang } = useSelector((state) => state?.UI); //ar or en
  const completedPercentage = ((client_progress / (course_lessons || 1)) * 100) / 100

  return (
    <div className="card">
      <div className="card-body">
        <div className="media">
          <div className="media-left">
            <Link to={`/partners/courses/${course_id}`}>
              <img
                src={course_image}
                alt="Card cap"
                width="100"
                className="rounded"
              />
            </Link>
          </div>
          <div className="media-body">
            <h4 className="card-title hero__lead m-0">
              <Link to={`/partners/courses/${course_id}`}>{Course[`course_name_${lang}`]}</Link>
            </h4>

            <small className="text-muted">
              Enrolled At : {moment(selection_date).format('MMMM Do YYYY, h:mm:ss a')}
            </small>
            {/* <small className="text-muted">
              Lessons: {FinishedNumberOfLessons} of {TotalNumberOfLessons}
            </small> */}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {completedPercentage === 0 && (
            <>
              <Link
                to={`/partners/courses/${course_id}`}
                className="btn btn-primary btn-sm ml-2"
              >
                Start{" "}
                <i className="material-icons btn__icon--right">
                  play_circle_outline
                </i>{" "}
              </Link>
            </>
          )}
          {client_progress / (course_lessons || 1) > 0 &&
            client_progress / (course_lessons || 1) < 1 && (
              <>
                <Link
                  to={`/partners/courses/${course_id}`}
                  className="btn btn-primary btn-sm"
                >
                  Continue{" "}
                  <i className="material-icons btn__icon--right">
                    play_circle_outline
                  </i>
                </Link>
              </>
            )}
          {client_progress / (course_lessons || 1) === 1 && (
            <>
              <Link
                to={`/partners/courses/${course_id}`}
                className="btn btn-success btn-sm"
              >
                Completed{" "}
                <i className="material-icons btn__icon--right">check</i>
              </Link>
              <Link
                to={`/partners/courses/${course_id}`}
                className="btn btn-white btn-sm ml-2"
              >
                Restart{" "}
                <i className="material-icons btn__icon--right">replay</i>{" "}
              </Link>
            </>
          )}
        </div>
      </div>

      {/* <div className="progress rounded-0">
        <div
          className={`progress-bar progress-bar-striped ${client_progress / (course_lessons || 1) === 1 ? "bg-success" : " bg-primary"
            }`}
          role="progressbar"
          style={{ width: `${completedPercentage}%` }}
          aria-valuenow={completedPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div> */}
      <div className="card-footer bg-white justify-content-end d-none">

        {/* 
        <div className="rating my-auto ml-auto">
          {[...Array(5)].map((_, index) => (
            <i key={index} className="material-icons">
              {renderStarIcon(index, course_rating)}
            </i>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default SingleMyCourseCard;
