import * as requestFromServer from "./PartnersCoursesCrud";
import { genericSetData, PartnersCoursesSlice } from "./PartnersCoursesSlice";
import { UISlice } from "../UISlice";

// const { actions: PartnersCoursesActions } = PartnersCoursesSlice;
const { actions: UIActions } = UISlice;

export const fetchPartnersMyCourses = () => (dispatch) => {
  dispatch(UIActions.openLoader());
  return requestFromServer.fetchMyCourses()
    .then((response) => {
      if (response.data.status) {
        dispatch(
          genericSetData({
            keyName: 'MyCourses',
            keyValue: response.data.data,
          })
        );
      }
    })
    .catch((error) => {
      // dispatch(PartnersCoursesActions.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

export const fetchSingleCourse = (course_id) => (dispatch) => {
  dispatch(UIActions.openLoader());
  return requestFromServer.fetchSingleCourse(course_id)
    .then((response) => {
      if (response.data.status) {
        dispatch(
          genericSetData({
            keyName: 'singleCourseInfo',
            keyValue: response.data.data[0],
          })
        );
      }
    })
    .catch((error) => {
      // dispatch(PartnersCoursesActions.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

export const rateSingleCourse = (course_id, rating, reviewValue, onSuccess) => (dispatch) => {
  dispatch(UIActions.openLoader());
  return requestFromServer.rateSingleCourse(course_id, rating, reviewValue)
    .then((response) => {
      if (response.data.status) {
        if (onSuccess) onSuccess()
      }
    })
    .catch((error) => {
      // dispatch(PartnersCoursesActions.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

// =====================================
export const fetchSingleCourseLesson = (course_id, lessonId) => (dispatch) => {
  dispatch(UIActions.openLoader());
  return requestFromServer.fetchSingleCourseLesson(course_id, lessonId)
    .then((response) => {
      if (response.data.status) {
        dispatch(
          genericSetData({
            keyName: 'singleLessonInfo',
            keyValue: response.data.data,
          })
        );
      }
    })
    .catch((error) => {
      // dispatch(PartnersCoursesActions.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

