import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import CustomPagination from "../../../Components/CustomPagination";
import { DATATABLE_CUSTOM_STYLES } from "../../../constants/datatableStyles";
import { createColumns } from "../../../helpers";
import { canCreateTaswiaApi, taswiaListApi } from "../../../apis";
import debounce from "lodash.debounce"; // Import lodash debounce

const SettlementList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [requestsData, setRequestsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [canCreateTaswia, setCanCreateTaswia] = useState(false);

  // Debounced filter handler
  const debouncedHandleFilter = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setCurrentPage(1);
    }, 300),
    [] // Only recreate debounce function once
  );

  const handleFilter = (e) => {
    debouncedHandleFilter(e.target.value);
  };

  useLayoutEffect(() => {
    const checkCanCreateTaswia = async () => {
      try {
        const response = await canCreateTaswiaApi();
        if (response?.data?.data) {
          setCanCreateTaswia(true);
        } else {
          setCanCreateTaswia(false);
        }
      } catch (error) {
        console.error("Error checking taswia creation permission:", error);
        setCanCreateTaswia(false); // Hide button in case of error
      }
    };

    checkCanCreateTaswia();
  }, []);

  // Function to fetch data from the API with parameters
  const fetchData = async () => {
    setIsLoading(true); // Set loading to true before fetching data
    try {
      // Pass the necessary parameters to taswiaListApi
      const response = await taswiaListApi(
        searchTerm,
        startDate,
        endDate,
        currentPage
      );
      setRequestsData(response.data.data || []); // Set the requests data or empty array if no data
      setPagination(response.data.paginator || {}); // Set the pagination data or empty object if no paginator
    } catch (error) {
      console.error("Failed to fetch data:", error); // Handle errors here
      setRequestsData([]); // Set empty data on error
      setPagination({}); // Reset pagination on error
    } finally {
      setIsLoading(false); // Set loading to false after data fetching is done
    }
  };

  // Effect to fetch data on state changes
  useEffect(() => {
    fetchData();
  }, [searchTerm, startDate, endDate, currentPage]); // Dependencies for useEffect

  // Effect to update the URL parameters when state changes
  useEffect(() => {
    const params = new URLSearchParams();
    if (searchTerm) params.set("search", searchTerm);
    if (startDate) params.set("startDate", startDate);
    if (endDate) params.set("endDate", endDate);
    params.set("page", currentPage);
    navigate({ search: params.toString() });
  }, [searchTerm, startDate, endDate, currentPage, navigate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const hiddenColumns = [
    "id",
    "client_id",
    "tasiwa_id",
    "created_by",
    "display_partners_to_include_list",
    "display_included_partners_list",
    "display_history_list",
    "display_end_list",
    "total_flashout",
    "total_unused",
  ];

  const customColumns = [
    {
      name: "Action",
      selector: (row) => "Settlement - " + row.tasiwa_id,
      ui: (row) => {
        const isFinished = row.status === "Finished";
        const isCurrent = row.status === "Current";
        return (
          <div className="d-flex gap-2">
            {isFinished ? (
              <Link
                to={`/settlement-history/${row.tasiwa_id}`}
                className="tw-text-primary tw-font-semibold"
              >
                History
              </Link>
            ) : isCurrent ? (
              <>
                <Link
                  to={`/settlement/${row.tasiwa_id}/partner-list`}
                  className="tw-text-primary tw-font-semibold"
                >
                  Partners List
                </Link>
                <div className="">|</div>
                <Link
                  to={`/end-settlement/${row.tasiwa_id}`}
                  className="ml-2 tw-text-primary tw-font-semibold"
                >
                  End Settlement
                </Link>
              </>
            ) : null}
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => {
    return createColumns(requestsData, customColumns, hiddenColumns);
  }, [requestsData]);

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Settlement List </h4>
        {canCreateTaswia && (
          <div className="primary-btn">
            <Link
              to={"/new-settlement"}
              className="text-white text-decoration-none"
            >
              New Settlement
            </Link>
          </div>
        )}
      </div>

      <div className="bg-white flex flex-col mt-4">
        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <div className="relative tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mb-4 tw-flex-col md:tw-flex-row ">
            <div className="tw-grow tw-w-full">
              <label htmlFor="searchInput" className="sr-only">
                Search by name
              </label>
              <input
                id="searchInput"
                type="text"
                onChange={handleFilter}
                defaultValue={searchTerm}
                className="border border-blue-300 rounded-lg p-2 tw-w-full"
                placeholder="Search by name"
              />
            </div>
            <div className="relative tw-flex tw-gap-4 tw-justify-center tw-items-center tw-flex-col md:tw-flex-row  tw-w-full">
              <div className="tw-flex tw-items-center">
                <label
                  htmlFor="dateFrom"
                  className="!tw-m-0 tw-w-[80px] tw-shrink-0"
                >
                  Date From
                </label>
                <Flatpickr
                  id="dateFrom"
                  value={startDate}
                  onChange={([date]) => setStartDate(date)}
                  className="form-control border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="tw-flex tw-items-center">
                <label
                  htmlFor="dateTo"
                  className="!tw-m-0 tw-w-[80px] tw-shrink-0"
                >
                  Date To
                </label>
                <Flatpickr
                  id="dateTo"
                  value={endDate}
                  onChange={([date]) => setEndDate(date)}
                  className="form-control border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={requestsData}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={pagination?.last_page}
              currentPage={pagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettlementList;
