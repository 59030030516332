import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ForgetPasswordAction } from "../../Redux/Auth/AuthActions";
import { absoluteUrl } from "../../_helper";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { emailRegex } from "../../constants/globals";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let ForgetPasswordInitialData = {
    email: "",
  };

  const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Required"),
  });

  const onErrorHandler = (word) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: word,
    });
  };

  const onSuccessHandler = (word) => {
    Swal.fire({
      icon: "success",
      title: "Please Check Your Email",
      text: word,
    });
  };

  return (
    <>
      <img
        src={absoluteUrl("imgs/logo-white.png")}
        className="login-logo mx-auto"
        alt=""
      />
      <h3 className="c-white text-center mt-3">Forget Password</h3>
      <Formik
        initialValues={ForgetPasswordInitialData}
        validationSchema={forgetPasswordSchema}
        onSubmit={(values) => {
          // same shape as initial values
          dispatch(
            ForgetPasswordAction(
              {
                email: values?.email,
                type: "client",
              },
              onSuccessHandler,
              onErrorHandler
            )
          );
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group mb-3">
              {/* <label className='c-white' htmlFor="email">Email</label> */}
              <div className="input-icon">
                <span className="fa fa-envelope login-icon-field"></span>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  className={`form-control pr-3 auth-form-input  ${
                    errors.email && touched.email ? "is-invalid" : ""
                  }`}
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary  auth-form-submit-btn mt-3"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>

      <div className="mt-4 d-block text-center">
        <Link
          className="text-white"
          style={{ fontSize: "20px" }}
          to={"/auth/login"}
        >
          Back to login
        </Link>
      </div>
    </>
  );
};

export default ForgetPassword;
