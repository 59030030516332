import React from "react";
import { Field } from "formik";

const FormField = ({
  name,
  label,
  type = "text",
  placeholder,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  value,
}) => {
  return (
    <div className="col-md-4 mb-3">
      <label className="form-label required-label" htmlFor={name}>
        {label}
      </label>
      <Field
        type={type}
        name={name}
        className={`form-control ${
          touched[name] && errors[name] ? "custom-invalid-input" : ""
        }`}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={
          type === "text" && name !== "phone"
            ? handleChange
            : (e) => {
                const { value } = e.target;
                if (name === "phone") {
                  const sanitizedValue = value.replace(/\D/g, "");
                  const truncatedValue = sanitizedValue.slice(0, 11);
                  setFieldValue(name, truncatedValue);
                } else {
                  setFieldValue(name, value);
                }
              }
        }
        value={value}
      />
      {touched[name] && errors[name] && (
        <div className="invalid-feedback">{errors[name]}</div>
      )}
    </div>
  );
};

export default FormField;
