import React, { useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { updateForm } from '../Redux/whatDoYouHave/AuthSlice';
import { useDispatch } from 'react-redux';
import { ResettPasswordAction, validateResetPasswordToken } from '../../Redux/Auth/AuthActions';
import { absoluteUrl } from '../../_helper';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';


const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();



    const ResetPasswordInitialData = {
        password: '',
        password_confirmation: ''
    }

    const resetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Password too short')
            .required('Required'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Password confirmation is required')
    });

    const removeQueryParam = () => {
        // Create a new URL without any query parameters
        const newUrl = location.pathname;

        // Update the URL without reloading the page
        navigate(newUrl, { replace: true });
    };

    const onErrorHandler = (word) => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: word,
        });
    }

    const onRestPasswordErrorHandler = (word) => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: word,

        }).then((result) => {
            if (result.isConfirmed) {
                if (word === "Token Has Expired") navigate('/')
            }
        });
    }

    const onRestPasswordSuccessfully = () => {
        Swal.fire({
            icon: "success",
            title: "success",
            text: 'your Password was changed successfully',
        });

        // to redirect to home (which is will be login)
        navigate('/')
    }


    useEffect(() => {
        if (searchParams.get('email') && searchParams.get('token')) {
            let email = searchParams.get('email');
            let token = searchParams.get('token')

            // if there is email and password (by redirection link from Email)
            // check email and token (if valid)>> remove email and link search paramters & save email and token info @forget password info @redux
            dispatch(validateResetPasswordToken(
                {
                    type: 'client',
                    email,
                    token
                },
                // onSuccess
                removeQueryParam,
                onErrorHandler
            ))
        }

    }, [])


    return (
        <>
            <img src={absoluteUrl('imgs/logo-white.png')} className='login-logo mx-auto' alt='' />
            <h3 className="c-white text-center mt-3">Reset Password</h3>
            <Formik
                initialValues={ResetPasswordInitialData}
                validationSchema={resetPasswordSchema}
                onSubmit={(values) => {
                    // same shape as initial values
                    dispatch(ResettPasswordAction(
                        values,
                        onRestPasswordSuccessfully,
                        onRestPasswordErrorHandler,

                    ));
                }}
            >
                {({ errors, touched }) => (
                    <Form>

                        <div className="form-group mb-3 position-relative">
                            {/* <label className='c-white' htmlFor="password">Password</label> */}
                            <div className="input-icon">
                            <span className="fa fa-lock login-icon-field"></span>
                            <Field
                                name="password"
                                type='password'
                                placeholder="Password"
                                className={`form-control pr-3 auth-form-input  ${errors.password && touched.password ? 'is-invalid' : ''
                                    }`}
                            />
                            </div>
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                            />
                        </div>
                        <div className="form-group mb-3 position-relative">
                            {/* <label className='c-white' htmlFor="password">Confirm Password</label> */}
                            <div className="input-icon">
                            <span className="fa fa-lock login-icon-field"></span>
                            <Field
                                name="password_confirmation"
                                type="password"
                                placeholder="Confirm Password"
                                className={`form-control pr-3 auth-form-input  ${errors.password_confirmation && touched.password_confirmation ? 'is-invalid' : ''
                                    }`}
                            />
                            </div>
                            <ErrorMessage
                                name="password_confirmation"
                                component="div"
                                className="invalid-feedback"
                            />
                        </div>

                        <button type="submit" className="btn btn-primary  auth-form-submit-btn mt-3">
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>

        </>
    );
};

export default ResetPassword;
