import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProfileSection from "../../../Components/ProfileSection";
import { viewPartnerSettlementDetailsApi } from "../../../apis";
import BackBtn from "../../../Components/BackBtn";
import DataTable from "react-data-table-component";
import CustomPagination from "../../../Components/CustomPagination";
import { DATATABLE_CUSTOM_STYLES } from "../../../constants/datatableStyles";
import { debounce } from "lodash";
import { createColumns } from "../../../helpers";
import TableSection from "../../../Components/TableSection";

const SettledPartner = () => {
  const [partnerData, setPartnerData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id: taswiaId, partnerId } = useParams();

  // For PV Transactions pagination
  const [pvPage, setPvPage] = useState(1);

  // For Direct Transactions pagination
  const [directPage, setDirectPage] = useState(1);

  const fetchData = async (page, pageParam) => {
    try {
      const response = await viewPartnerSettlementDetailsApi(
        taswiaId,
        partnerId,
        page,
        pageParam
      );
      setPartnerData(response.data.data);
      setLoading(false);
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred while fetching data"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch initial data for both PV and Direct transactions
    fetchData(pvPage, "pv_upliner_page");
  }, [pvPage, taswiaId, partnerId]);

  useEffect(() => {
    fetchData(directPage, "direct_pv_page");
  }, [directPage, taswiaId, partnerId]);

  if (loading) return <div>Loading...</div>;

  if (error) return <div className="alert alert-danger">{error}</div>;

  if (!partnerData) return <div>No partner data available</div>;

  const {
    partner_profile,
    settlement_data,
    partner_settlement_summary,
    partner_settlement_details,
  } = partnerData;
  const {
    partner_settlement_pv_transactions,
    partner_settlement_direct_transactions,
  } = partner_settlement_details;

  const { image, wallet_data, ...filteredPartnerData } = partner_profile;

  const partnerDetails = {
    ...filteredPartnerData,
    ...wallet_data,
  };

  // Table Columns for PV Transactions

  const pvColumns = createColumns(partner_settlement_pv_transactions.data);

  // Table Columns for Direct Transactions
  const directColumns = createColumns(
    partner_settlement_direct_transactions.data
  );

  // Debounce pagination change to prevent too many rapid updates
  const handlePvPageChange = debounce((page) => {
    setPvPage(page);
  }, 300);

  const handleDirectPageChange = debounce((page) => {
    setDirectPage(page);
  }, 300);

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-justify-between">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">
          Settlement Partner Details
        </h4>

        <BackBtn
          text={"Back To List"}
          link={`/settlement/${taswiaId}/partner-list`}
        />
      </div>
      <ProfileSection
        title="Partner Profile"
        data={partnerDetails}
        img={image}
        link={{
          url: `/profile/${partnerId}`,
          title: "Go to profile",
        }}
      />
      <ProfileSection title="Settlement Data" data={settlement_data} />
      <ProfileSection
        title="Partner Settlement Summary"
        data={partner_settlement_summary}
      />
      <TableSection
        tableData={partner_settlement_details.table}
        title="Settlement Details"
      />
      {/* PV Transactions Data Table */}
      <section className="tw-container tw-mx-auto tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md">
        <h3 className="tw-text-xl tw-font-semibold tw-text-red-600 tw-mb-4 tw-border-b tw-pb-2">
          PV Transactions
        </h3>
        <div className="tw-overflow-x-auto">
          <DataTable
            columns={pvColumns}
            data={partner_settlement_pv_transactions.data}
            customStyles={DATATABLE_CUSTOM_STYLES}
            sortServer
          />
          <CustomPagination
            totalPages={partner_settlement_pv_transactions.paginator.last_page}
            currentPage={
              partner_settlement_pv_transactions.paginator.current_page
            }
            onPageChange={handlePvPageChange} // Debounced PV Page change
          />
        </div>
      </section>
      {/* Direct Transactions Data Table */}
      <section className="tw-container tw-mx-auto tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md">
        <h3 className="tw-text-xl tw-font-semibold tw-text-red-600 tw-mb-4 tw-border-b tw-pb-2">
          Direct Transactions
        </h3>
        <div className="tw-overflow-x-auto">
          <DataTable
            columns={directColumns}
            data={partner_settlement_direct_transactions.data}
            customStyles={DATATABLE_CUSTOM_STYLES}
            sortServer
          />
          <CustomPagination
            totalPages={
              partner_settlement_direct_transactions.paginator.last_page
            }
            currentPage={
              partner_settlement_direct_transactions.paginator.current_page
            }
            onPageChange={handleDirectPageChange} // Debounced Direct Page change
          />
        </div>
      </section>
    </div>
  );
};

export default SettledPartner;
