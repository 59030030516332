import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { debounce } from "lodash";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

import { useDispatch, useSelector } from "react-redux";
import {
  justValidateInput,
  saveStepData,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import { clearStepErrors } from "../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../../constants/globals";

const PartnerStepOne = () => {
  const dispatch = useDispatch();
  const { step1 } = useSelector((state) => state?.AddOrEditPartner);
  const { validationObject } = step1;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearStepErrors(1));
  }, []);

  // Async validation function
  const asyncValidation = async (finalObject) => {
    dispatch(justValidateInput({ ...finalObject, is_done: 0 }, 1));
  };

  // Debounced async validation function
  const debouncedAsyncValidation = debounce(asyncValidation, 1000); // Adjust debounce delay as needed

  // Initial form values
  const initialValues = {
    first_name: "",
    second_name: "",
    last_name: "",
    email: "",
    phone: "",
    phone_country_code: "+2",
    identification_type: "1",
    identification_number: "",
  };

  // Validation schema using Yup
  const StepOneSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    second_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^(010|011|012|015)\d{8}$/, {
        message:
          "Phone number must be 11 digits and start with 010, 011, 012, or 015",
        excludeEmptyString: true,
      }),
    // .test('phone-validation', 'Invalid phone number', value => isPhoneValid(value)),
    phone_country_code: Yup.string().required("Country code is required"),
    identification_type: Yup.string().required("Required"),
    identification_number: Yup.string().required("Required"),
  });

  const reFormatObject = (allFormValues) => {
    let finalObject = {};

    finalObject.first_name =
      allFormValues.first_name ?? initialValues.first_name;
    finalObject.second_name =
      allFormValues.second_name ?? initialValues.second_name;
    finalObject.last_name = allFormValues.last_name ?? initialValues.last_name;
    finalObject.email = allFormValues.email ?? initialValues.email;

    finalObject.phone_country_code =
      allFormValues.phone_country_code ?? initialValues.phone_country_code;
    finalObject.phone =
      allFormValues.phone.replace(allFormValues.phone_country_code, "") ??
      initialValues.phone;

    finalObject.identification_type = +(
      allFormValues.identification_type ?? initialValues.identification_type
    );
    finalObject.identification_number =
      allFormValues.identification_number ??
      initialValues.identification_number;

    return finalObject;
  };
  const handleGenericInputChangeMultiple = (
    e,
    allFormValues,
    setFieldValue
  ) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    let finalObject = { ...reFormatObject(allFormValues) };
    // to handle to don't send it as zero
    if (finalObject.identification_number == 0)
      finalObject.identification_number = "";
    debouncedAsyncValidation({ ...finalObject, [name]: value });
  };

  // Form submit handler
  const handleSubmit = (values) => {
    if (Object.keys(validationObject).length === 0) {
      dispatch(
        saveStepData(
          { ...reFormatObject(values), is_done: 1 },
          1,
          false,
          (clientId) => {
            // here
            Swal.fire({
              icon: "success",
              title: "OTP has been sent to your email",
            });

            navigate(`/partners/edit/${clientId}`);
          }
        )
      );
    }
  };

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["eg", "us"].includes(iso2);
  });

  return (
    <>
      <div className="card-header">
        <h4 className="card-title mb-0">Step (1): Basic Data</h4>
      </div>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={StepOneSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="d-flex flex-column">
              <div className="row">
                {/* First Name */}
                <div className="col-md-4 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="first_name"
                  >
                    First Name
                  </label>
                  <Field
                    type="text"
                    name="first_name"
                    className={`form-control ${
                      touched.first_name &&
                      (errors.first_name ||
                        validationObject[`first_name`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="First Name"
                    onChange={(e) =>
                      handleGenericInputChangeMultiple(e, values, setFieldValue)
                    }
                    onBlur={handleBlur}
                  />
                  {touched.first_name && (
                    <>
                      {validationObject[`first_name`]?.length > 0 ? (
                        <>
                          {validationObject[`first_name`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.first_name && (
                            <div className="invalid-feedback">
                              {errors.first_name}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Second Name */}
                <div className="col-md-4 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="second_name"
                  >
                    Second Name
                  </label>
                  <Field
                    type="text"
                    name="second_name"
                    className={`form-control ${
                      touched.second_name &&
                      (errors.second_name ||
                        validationObject[`second_name`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="Second Name"
                    onChange={(e) =>
                      handleGenericInputChangeMultiple(e, values, setFieldValue)
                    }
                    onBlur={handleBlur}
                  />
                  {touched.second_name && (
                    <>
                      {validationObject[`second_name`]?.length > 0 ? (
                        <>
                          {validationObject[`second_name`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.second_name && (
                            <div className="invalid-feedback">
                              {errors.second_name}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Last Name */}
                <div className="col-md-4 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="last_name"
                  >
                    Last Name
                  </label>
                  <Field
                    type="text"
                    name="last_name"
                    className={`form-control ${
                      touched.last_name &&
                      (errors.last_name ||
                        validationObject[`last_name`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="Last Name"
                    onChange={(e) =>
                      handleGenericInputChangeMultiple(e, values, setFieldValue)
                    }
                    onBlur={handleBlur}
                  />
                  {touched.last_name && (
                    <>
                      {validationObject[`last_name`]?.length > 0 ? (
                        <>
                          {validationObject[`last_name`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.last_name && (
                            <div className="invalid-feedback">
                              {errors.last_name}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Phone Number */}
                <div className="col-md-6 mb-3">
                  <label className="form-label required-label" htmlFor="phone">
                    Phone Number
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    className={`form-control ${
                      touched.phone &&
                      (errors.phone || validationObject[`phone`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="Phone"
                    maxLength="11"
                    inputMode="numeric"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      // Remove any non-digit characters from the input
                      const sanitizedValue = value.replace(/\D/g, "");
                      // Limit to 6 digits
                      const truncatedValue = sanitizedValue.slice(0, 11);
                      // Update state and form field value
                      setFieldValue(name, truncatedValue);
                      let finalObject = { ...reFormatObject(values) };
                      // to handle to don't send it as zero
                      if (finalObject.identification_number == 0)
                        finalObject.identification_number = "";
                      debouncedAsyncValidation({
                        ...finalObject,
                        [name]: value,
                      });
                    }}
                    onBlur={handleBlur}
                  />
                  {touched.phone && (
                    <>
                      {validationObject[`phone`]?.length > 0 ? (
                        <>
                          {validationObject[`phone`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.phone && (
                            <div className="invalid-feedback">
                              {errors.phone}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Email */}
                <div className="col-md-6 mb-3">
                  <label className="form-label required-label" htmlFor="email">
                    Email
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className={`form-control ${
                      touched.email &&
                      (errors.email || validationObject[`email`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="Email"
                    onChange={(e) =>
                      handleGenericInputChangeMultiple(e, values, setFieldValue)
                    }
                    onBlur={handleBlur}
                  />

                  {touched.email && (
                    <>
                      {validationObject[`email`]?.length > 0 ? (
                        <>
                          {validationObject[`email`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Identification Type */}
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="identification_type"
                  >
                    Identification Type
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.identification_type &&
                      (errors.identification_type ||
                        validationObject[`identification_type`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="identification_type"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleGenericInputChangeMultiple(
                        e,
                        values,
                        setFieldValue
                      );
                      setFieldValue("identification_number", "");
                    }}
                  >
                    {/* <option></option> */}
                    <option value="1">National ID</option>
                    <option value="2">Passport</option>
                  </Field>

                  {touched.identification_type && (
                    <>
                      {validationObject[`identification_type`]?.length > 0 ? (
                        <>
                          {validationObject[`identification_type`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.identification_type && (
                            <div className="invalid-feedback">
                              {errors.identification_type}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Identification Number */}
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="identification_number"
                  >
                    Identification Number
                  </label>
                  <Field
                    type="text"
                    name="identification_number"
                    className={`form-control ${
                      touched.identification_number &&
                      errors.identification_number
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="Identification Number"
                    // identification_type == 1 >> national Id
                    // maxLength={values?.identification_type == 1 ? '14' : '9'}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (values?.identification_type == 1) {
                        // Allow typing up to 14 digits
                        if (/^\d{0,14}$/.test(value)) {
                          handleGenericInputChangeMultiple(
                            e,
                            values,
                            setFieldValue
                          );
                        } else {
                        }
                      } else {
                        // Allow typing the first letter and up to 8 digits
                        if (/^[a-zA-Z]?\d{0,8}$/.test(value)) {
                          handleGenericInputChangeMultiple(
                            e,
                            values,
                            setFieldValue
                          );
                        } else {
                        }
                      }
                    }}
                    onBlur={handleBlur}
                  />

                  {touched.identification_number && (
                    <>
                      {validationObject[`identification_number`]?.length > 0 ? (
                        <>
                          {validationObject[`identification_number`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.identification_number && (
                            <div className="invalid-feedback">
                              {errors.identification_number}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary ml-auto">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PartnerStepOne;
