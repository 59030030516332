import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestsData: [],
  requestDetails: null,
  isLoading: false,
  error: "",
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0,
  },
};

export const RequestsSlice = createSlice({
  name: "Requests",
  initialState,
  reducers: {
    setRequestsData: (state, action) => {
      state.requestsData = action.payload.data;
      state.pagination = action.payload.paginator;
    },
    clearRequestsData: (state) => {
      state.requestsData = [];
      state.pagination = {
        current_page: 1,
        last_page: 1,
        per_page: 15,
        total: 0,
      };
    },
    setRequestDetails: (state, action) => {
      state.requestDetails = action.payload;
    },
    clearRequestDetails: (state) => {
      state.requestDetails = null;
    },
    catchError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setRequestsData,
  clearRequestsData,
  setRequestDetails,
  clearRequestDetails,
  catchError,
  setLoading,
} = RequestsSlice.actions;
export default RequestsSlice.reducer;
