import React, { useEffect } from 'react'
import SingleMyCourseCard from './mycourses/SingleMyCourseCard'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartnersMyCourses } from '../../../Redux/PartnersCourses/PartnersCoursesActions';

const Mycourses = () => {
    const { MyCourses } = useSelector((state) => state?.partnersCourses);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPartnersMyCourses())
    }, [])

    return (
        <div className='container'>
            <h1 className="h2 text-capitalize">
                My Courses
            </h1>
            <div className="card-columns">
                {MyCourses.length > 0 && MyCourses.map((course, courseIndex) => <React.Fragment key={'Course' + courseIndex}>
                    <SingleMyCourseCard Course={course} />
                </React.Fragment>)}
            </div>

        </div>
    )

}

export default Mycourses
