import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import AnnouncementCard from "./AnnouncementCard";

const AnnouncementSlider = ({ announcements }) => {
  return (
    <Swiper
      className="w-full h-[30vh]"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      //   scrollbar={{ draggable: true }}
    >
      {announcements.map((announcement, index) => (
        <SwiperSlide className="px-20" key={index}>
          <AnnouncementCard announcement={announcement} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AnnouncementSlider;
