export const DATATABLE_CUSTOM_STYLES = {
  headRow: {
    style: {
      backgroundColor: "rgba(107, 114, 128, 0.2)",
    },
  },
  headCells: {
    style: {
      color: "#1f2937",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      color: "#4b5563",
      padding: "12px",
    },
  },
  rows: {
    style: {
      "&:nth-child(odd)": {
        backgroundColor: "rgba(209, 213, 219, 0.2)", // Light grey for odd rows
      },
      "&:nth-child(even)": {
        backgroundColor: "white", // White for even rows
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: "rgba(209, 213, 219, 0.4)",
    },
  },
};
