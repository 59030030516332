import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

const SelectedPackageCourseRow = ({ course, courseIndex, ...props }) => {
    const { lang } = useSelector((state) => state?.UI); //ar or en

    return (
        <tr className="selected">
            
            <td>{courseIndex + 1}</td>

            <td>
                <div className="media align-items-center">
                    {course?.image &&
                        <div className="avatar avatar-sm mr-3">
                            <img src={course?.image} alt={course[`course_description_${lang}`]} className="avatar-img rounded-circle" />
                        </div>
                    }
                    <div className="media-body">
                        <span className="js-lists-values-employee-name">{course[`course_name_${lang}`]}</span>
                    </div>
                </div>
            </td>
            {/* <td></td> */}
            <td>{course?.e_money}</td>
        </tr>
    )
}

export default SelectedPackageCourseRow
