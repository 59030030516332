import React from "react";
import Modal from "react-modal";

const RejectModal = ({
  isOpen,
  onClose,
  handleVerifyPayment,
  rejectionNote,
  setRejectionNote,
  requestDetails,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Reject Request Modal"
    className={"flex-col-center"}
  >
    <div className="custom-modal shadow-lg">
      <div className="modal-header">
        <h2>Reject Request</h2>
        <button onClick={onClose} className="btn btn-danger">
          Close
        </button>
      </div>
      <div className="modal-content">
        <div className="request-header">
          <p>
            Request No: <span>{requestDetails.request_number}</span>
          </p>
          <p>
            Request Date:{" "}
            <span>
              {new Date(requestDetails.request_date).toLocaleString()}
            </span>
          </p>
          <p>
            Request Status: <span>{requestDetails.request_status}</span>
          </p>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div>
              <label>Rejection Notes *</label>
              <textarea
                value={rejectionNote}
                onChange={(e) => setRejectionNote(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="col-md-4">
            <div className="request-body d-flex flex-column">
              <div className="request-section">
                <h3>Registration Wallet Balance for</h3>
                <p>{requestDetails.create_emoney_to}</p>
              </div>
              <div className="request-section">
                <h3>Total Direct Partners</h3>
                <p>{requestDetails.total_direct_partners}</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => handleVerifyPayment("reject")}
              >
                Confirm
              </button>
              <button onClick={onClose} className="btn btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default RejectModal;
