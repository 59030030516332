import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStep4selectedPackage,
  setStep4selectedPackageFullObject,
} from "../../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";

const Step4PackageCard = ({ packageDetails, ...props }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state?.UI); // ar or en
  const { step4 } = useSelector((state) => state?.AddOrEditPartner);
  const { selectedPackage, myWalletBalance, AllPackages } = step4;
  return (
    <div className="card card-group-row__card text-center o-hidden card--raised navbar-shadow h-100">
      <div
        className="tw-p-2"
        style={{ backgroundColor: packageDetails?.package_color }}
      >
        <h4 className="mb-8pt tw-text-lg !tw-text-white">
          {packageDetails[`package_name_${lang}`]}
        </h4>
      </div>

      <div className="card-body card-body-package d-flex flex-column">
        {/* Package type */}
        {/* <div className="flex-grow-1">
                    <h3 className="title">Package Type</h3>
                    <p className="tw-text-sm tw-text-gray-500/60 !tw-mb-0">{packageDetails[`package_name_${lang}`]}</p>
                </div> */}
        {/* Value */}
        <div className="flex-grow-1">
          <h3 className="tw-text-base text-primary !tw-mb-2">Value</h3>
          <p className="tw-text-sm tw-text-gray-500/60 !tw-mb-0">
            {packageDetails[`total_price`]} E.M{" "}
          </p>
          {!!packageDetails?.total_package_pv && (
            <p className="tw-text-sm tw-text-gray-500/60 !tw-mb-0">
              {packageDetails?.total_package_pv} PV{" "}
            </p>
          )}
        </div>

        {/* Courses Included */}
        <div className="flex-grow-1">
          <h3 className="tw-text-base text-primary !tw-mb-2">
            Courses Included ({packageDetails?.courses?.length})
          </h3>
          {packageDetails?.courses?.length > 0 &&
            packageDetails?.courses.map((course, courseIndex) => (
              <p
                className="tw-text-sm tw-text-gray-500/60 !tw-mb-0"
                key={courseIndex}
              >
                {course[`course_name_${lang}`]}
              </p>
            ))}
        </div>
      </div>
      <div
        className={`card-footer ${
          myWalletBalance?.balance <= packageDetails[`total_price`]
            ? "card-footer-danger"
            : ""
        }`}
        style={{ minHeight: 50 }}
      >
        {myWalletBalance?.balance >= packageDetails[`total_price`] ? (
          <div
            className="custom-control custom-radio"
            style={{ marginTop: -15 }}
          >
            <input
              type="radio"
              className="custom-control-input"
              value={packageDetails?.id || ""}
              id={`package-${packageDetails?.id || ""}`} // Unique ID for each radio button
              checked={packageDetails?.id == selectedPackage}
              onChange={(e) => {
                dispatch(setStep4selectedPackage(e.target.value));
                let currentPackageIndex = AllPackages.findIndex(
                  (el) => el?.id == e.target.value
                );
                dispatch(
                  setStep4selectedPackageFullObject(
                    AllPackages[currentPackageIndex]
                  )
                );
              }}
            />
            <label
              htmlFor={`package-${packageDetails?.id || ""}`}
              className="custom-control-label"
            >
              {/* Select this package */}
            </label>
          </div>
        ) : (
          <>
            <h3 className="mb-0 fs-6">insufficient e-money</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default Step4PackageCard;
