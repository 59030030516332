import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage
import sessionStorage from "redux-persist/lib/storage/session"; // sessionStorage

// axios
import axios from "axios";
import setupAxios from "./axiosInterceptor";

// slices
import { UISlice } from "./Redux/UISlice";
import { AuthSlice, clearLoggedInUser } from "./Redux/Auth/AuthSlice";
import { AddOrEditPartnerSlice } from "./Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import { TreeSlice } from "./Redux/Tree/TreeSlice";
import { PartnersSlice } from "./Redux/Partners/PartnersSlice";
import { RequestsSlice } from "./Redux/Requests/RequestsSlice";
import { ReplaceEmoneySlice } from "./Redux/ReplaceEmoney/ReplaceEmoneySlice";
import { AdminPartnersSlice } from "./Redux/AdminPartners/AdminPartnersSlice";
import { PartnersCoursesSlice } from "./Redux/PartnersCourses/PartnersCoursesSlice";

// Persist configurations
const authPersistConfig = {
  key: "auth",
  storage, // sessionStorage for AddOrEditPartner
};

// Persisted reducers
const persistedAuthReducer = persistReducer(
  authPersistConfig,
  AuthSlice.reducer
);

// Root reducer
const appReducer = combineReducers({
  UI: UISlice.reducer,
  Auth: persistedAuthReducer,
  AddOrEditPartner: AddOrEditPartnerSlice.reducer,
  Tree: TreeSlice.reducer,
  Partners: PartnersSlice.reducer,
  Requests: RequestsSlice.reducer,
  ReplaceEmoney: ReplaceEmoneySlice.reducer,
  partnersCourses: PartnersCoursesSlice.reducer,
  AdminPartners: AdminPartnersSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === clearLoggedInUser.type) {
    storage.removeItem("persist:root");
    sessionStorage.removeItem("persist:partner");
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

// Configure store
export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    }),
});

setupAxios(axios, store);

export const WLPersistor = persistStore(store);
