import * as requestFromServer from "./TreeCrud";
import { TreeSlice } from "./TreeSlice";
import { UISlice } from "../UISlice";

const { actions: TreeActions } = TreeSlice;
const { actions: UIActions } = UISlice;

export const fetchTree =
  (clientId, treeLevel = 3) =>
  (dispatch) => {
    dispatch(UIActions.openLoader());

    return requestFromServer
      .fetchTreeData(clientId, treeLevel)
      .then((response) => {
        if (response.status) {
          dispatch(
            TreeActions.setTreeData({
              treeData: response.data.data,
              treeMaxLevel: response.data.data.max_tree_level,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(TreeActions.catchError(error));
        dispatch(
          TreeActions.setTreeData({
            treeData: [],
            treeMaxLevel: null,
          })
        );
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };
