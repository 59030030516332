import * as requestFromServer from "./RequestsCrud";
import { RequestsSlice } from "./RequestsSlice";
import { UISlice } from "../UISlice";

const { actions: RequestsActions } = RequestsSlice;
const { actions: UIActions } = UISlice;

export const fetchRequests =
  (type, searchTerm = "", page = 1) =>
  (dispatch) => {
    dispatch(UIActions.openLoader());

    let fetchFunction;
    switch (type) {
      case "pending":
        fetchFunction = requestFromServer.fetchPendingRequests;
        break;
      case "accepted":
        fetchFunction = requestFromServer.fetchAcceptedRequests;
        break;
      case "rejected":
      default:
        fetchFunction = requestFromServer.fetchRejectedRequests;
        break;
    }

    return fetchFunction(searchTerm, page)
      .then((response) => {
        if (response.data.status) {
          dispatch(
            RequestsActions.setRequestsData({
              data: response.data.data,
              paginator: response.data.paginator,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(RequestsActions.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const viewPaymentVerification = (id) => (dispatch) => {
  dispatch(UIActions.openLoader());

  return requestFromServer
    .viewPaymentVerification(id)
    .then((response) => {
      if (response.status) {
        dispatch(RequestsActions.setRequestDetails(response.data.data));
      }
    })
    .catch((error) => {
      dispatch(RequestsActions.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

export const verifyClientPayment =
  (
    payment_verification_id,
    actionType,
    e_money,
    acceptance_note,
    rejection_note,
    onSuccess
  ) =>
  (dispatch) => {
    dispatch(UIActions.openLoader());

    const objectToSend = {
      payment_verification_id,
      action: actionType,
      acceptance_note,
      rejection_note,
    };
    if (actionType != "reject") {
      objectToSend.e_money = e_money;
    }

    return requestFromServer
      .verifyClientPayment({ ...objectToSend })
      .then((response) => {
        if (response.status) {
          dispatch(RequestsActions.clearRequestDetails());
          if (onSuccess) onSuccess();
        }
      })
      .catch((error) => {
        dispatch(RequestsActions.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };
