// current
import { createSlice } from "@reduxjs/toolkit";


export const intialState = {
  isLoading: false,
  error: '',
  currentStep: 1,
  step1: {
    validationObject: {},
    stepInfo: {}
  },
  step2: {
    validationObject: {},
    resendOtp: {
      counter: 0,
      isDisabled: false
    }
  },
  step3: {
    validationObject: {},
    EmoneyValidation: [],
    paymentMethods: [],
    message: '',
  },
  step4: {
    myWalletBalance: {
      // "balance": 0,
      // "wallet-type": "registration"
    },
    AllPackages: [],
    selectedPackage: "",
    selectedPackageFullObject: {},
    validationObject: {},
    isSubmittedFlag: false
  },
  step5: {
    validationObject: {},
    educationalLevels: [],
    jobs: [],
    countries: [],
    cities: [],
  },
  step6: {
    validationObject: {},

    showDirectOtherList: false,
    allDirectPartnersMatchsSearchKey: [],

    showUplinerOtherList: false,
    allUplinerPartnersMatchsSearchKey: [],

    leftOrRightUplinerPartner: []
  }
};


export const AddOrEditPartnerSlice = createSlice({
  name: "AddOrEditPartner",
  initialState: intialState,
  reducers: {

    catchError: (state, action) => {
      state.error = action.payload;
    },

    setCurrnetStep: (state, action) => {
      state.currentStep = action.payload;
    },

    setStepErrors: (state, action) => {
      const { errorsList, stepNumber } = action.payload;
      if (errorsList[0] !== "success validation") {
        if (state[`step${stepNumber}`]) {
          state[`step${stepNumber}`].validationObject = { ...errorsList };
        }
      }

      else {
        // clear validation object
        state[`step${stepNumber}`].validationObject = { ...{} };
      }
    },

    clearStepErrors: (state, action) => {
      const stepNumber = action.payload;
      state[`step${stepNumber}`].validationObject = { ...{} }
    },

    setCurrnetStepInfo: (state, action) => {
      const { stepInfo, stepNumber } = action.payload;
      state[`step${stepNumber}`].stepInfo = { ...stepInfo };
    },
    // ====step2
    setStep2IsDisabled: (state, action) => {
      const newDisabled = action.payload;
      state[`step2`].resendOtp.isDisabled = newDisabled;
    },
    setStep2setCounter: (state, action) => {
      const newCounter = action.payload;
      state[`step2`].resendOtp.counter = newCounter;
    },

    // step3
    setStep3EmoneyFromWalletErrors: (state, action) => {
      const { errorsList } = action.payload;
      state[`step3`].EmoneyValidation = errorsList;
    },

    setStep3paymentMethods: (state, action) => {
      const { paymentMethods } = action.payload;
      state[`step3`].paymentMethods = [...paymentMethods];
    },

    setStep3Message: (state, action) => {
      state[`step3`].message = action.payload;
    },

    // step4
    setPackages: (state, action) => {
      const { packages } = action.payload
      state[`step4`].AllPackages = packages;
    },

    setMyWalletBalance: (state, action) => {
      const { myWalletBalance } = action.payload
      state[`step4`].myWalletBalance = myWalletBalance;
    },

    setStep4ValidationError: (state, action) => {
      const { errorsList } = action.payload
      state[`step4`].validationObject = errorsList;
    },

    setStep4selectedPackage: (state, action) => {
      state[`step4`].selectedPackage = action.payload;
    },

    setStep4selectedPackageFullObject: (state, action) => {
      state[`step4`].selectedPackageFullObject = { ...action.payload };
    },

    turnOnStep4isSubmittedFlag: (state, action) => {
      state[`step4`].isSubmittedFlag = true;
    },

    genericStepDataByKey: (state, action) => {
      const { keyName, keyValue, stepNumber } = action.payload;
      if (state[`step${stepNumber}`]) {
        state[`step${stepNumber}`][keyName] = keyValue
      }
    },

    genericResetStep: (state, action) => {
      const stepNumber = action.payload;
      if (state[`step${stepNumber}`]) {
        state[`step${stepNumber}`] = intialState[`step${stepNumber}`]
      }
    },


    genericResetStepsReducer: (state, action) => {
      return { ...action.payload }
    },

  },
});

const actions = AddOrEditPartnerSlice.actions;
export const {
  // generic
  setCurrnetStep, clearStepErrors, setCurrnetStepInfo, setStepErrors, genericStepDataByKey, genericResetStep, genericResetStepsReducer,
  // step2
  setStep2IsDisabled, setStep2setCounter,
  // step3
  setStep3EmoneyFromWalletErrors, setStep3paymentMethods, setStep3Message,
  // step4
  setPackages, setMyWalletBalance, setStep4selectedPackage, setStep4selectedPackageFullObject, turnOnStep4isSubmittedFlag

} = actions;
