import clsx from "clsx";
import React from "react";

const AchievementCard = ({
  title,
  count,
  unit,
  borderClasses,
  customValueClasses,
}) => {
  return (
    <div
      className={`d-flex w-100 align-items-stretch justify-content-center tw-h-[120px] `}
    >
      <div
        className={`tw-bg-white tw-shadow-md w-100 tw-py-4 tw-px-2 tw-border-l-4 ${borderClasses}`}
      >
        {title && (
          <h4 className="card-title text-center text-capitalize tw-text-xl">
            {title}
          </h4>
        )}

        <div className="">
          <div className="d-flex justify-content-between flex-column align-items-center w-100 tw-gap-2">
            {count !== undefined && (
              <div
                className={clsx(
                  customValueClasses,
                  "tw-text-base tw-text-center"
                )}
              >
                {count}
              </div>
            )}

            {unit && <div className="ml-2 text-muted tw-text-xs">{unit}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchievementCard;
