import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { announcementDetailstApi } from "../../../apis"; // Adjust the import path as needed
import AnnouncementSection from "../../../Components/AnnouncementSection";

const AnnouncementDetails = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await announcementDetailstApi(id);
        if (response.status === 200) {
          setAnnouncement(response.data.data);
        } else {
          console.error("Failed to fetch announcement details");
        }
      } catch (error) {
        console.error("Error fetching announcement details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncement();
  }, [id]);

  if (loading) {
    return <div className="tw-text-center">Loading...</div>;
  }

  if (!announcement) {
    return <div className="tw-text-center">Announcement not found.</div>;
  }

  return (
    <div className="tw-w-full tw-mt-8">
      <h4 className="tw-text-2xl tw-font-medium tw-mb-8 tw-text-red-500">
        Announcement Details
      </h4>
      <div className="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
        <div className="tw-grid tw-grid-cols-2">
          <AnnouncementSection
            title="Title"
            data={announcement.announcement_title}
          />
          <AnnouncementSection
            title="Details"
            data={announcement.announcement_details}
          />
          <AnnouncementSection
            title="On Portal"
            data={announcement.on_portal}
          />
          <AnnouncementSection
            title="Schedule Date and Time"
            data={announcement.schedule_date}
          />
          <AnnouncementSection
            title="Announce For"
            data={announcement.announcement_for}
          />
          <AnnouncementSection
            title="Link Keyword"
            data={announcement.announcement_link_keyword}
          />
          {announcement.announcement_link && (
            <AnnouncementSection
              title="Link URL"
              data={announcement.announcement_link}
              isLink
            />
          )}
          {announcement.announcement_image && (
            <div className="tw-col-span-2">
              <h5 className="tw-text-lg tw-text-primary">Image:</h5>
              <img
                src={announcement.announcement_image}
                alt="Announcement"
                className="tw-w-full tw-max-w-xs tw-mt-2 tw-rounded-lg tw-shadow-md"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDetails;
