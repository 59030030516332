import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Swal from "sweetalert2";
import {
  createAnnouncement,
  announceForListApi,
  AnnouncementLinkKeywordApi,
} from "../../../apis"; // Adjust the import path as needed
import { useNavigate } from "react-router-dom";

const initialValues = {
  announcement_title: "",
  announcement_details: "",
  link_url: "",
  on_portal: "1", // Default to 'Yes'
  schedule_date: "",
  announce_for: "",
  link_text: "",
  image: null,
};

const validationSchema = Yup.object().shape({
  announcement_title: Yup.string().required("Title is required"),
  announcement_details: Yup.string(),
  link_url: Yup.string().nullable(),
  on_portal: Yup.string().required("Please select an option for On Portal"),
  schedule_date: Yup.date().required("Schedule date and time are required"),
  announce_for: Yup.string().required("Please select who to announce for"),
  link_text: Yup.string().required("Please select a link text"),
  image: Yup.mixed().nullable(),
});

const NewAnnouncement = () => {
  const [announceForOptions, setAnnounceForOptions] = useState([]);
  const [linkTextOptions, setLinkTextOptions] = useState([]);
  const navigate = useNavigate();

  // Fetch options for dropdowns
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [announceForResponse, linkTextResponse] = await Promise.all([
          announceForListApi(),
          AnnouncementLinkKeywordApi(),
        ]);

        if (announceForResponse.status === 200) {
          setAnnounceForOptions(announceForResponse.data.data);
        }

        if (linkTextResponse.status === 200) {
          setLinkTextOptions(linkTextResponse.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await createAnnouncement(values);
      Swal.fire({
        title: "Success",
        text: response.data.message || "Announcement created successfully.",
        icon: "success",
      });
      resetForm();
      navigate("/announcements");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to create announcement.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">
          Create New Announcement
        </h4>
      </div>

      <div className="tw-flex-col gap-6">
        <div className="card">
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, isSubmitting, values }) => (
                <Form>
                  {/* Title */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="announcement_title">
                      Title
                    </label>
                    <Field
                      type="text"
                      name="announcement_title"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="announcement_title"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Details */}
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="announcement_details"
                    >
                      Details
                    </label>
                    <Field
                      as="textarea"
                      name="announcement_details"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="announcement_details"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* On Portal */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="on_portal">
                      On Portal
                    </label>
                    <Field
                      as="select"
                      name="on_portal"
                      className="form-control"
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Field>
                    <ErrorMessage
                      name="on_portal"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Schedule Date and Time */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="schedule_date">
                      Schedule Date and Time
                    </label>
                    <Flatpickr
                      id="schedule_date"
                      name="schedule_date"
                      className="form-control"
                      value={values.schedule_date}
                      onChange={(date) => {
                        const formattedDate = date[0]
                          .toISOString()
                          .slice(0, 19)
                          .replace("T", " ");
                        setFieldValue("schedule_date", formattedDate);
                      }}
                      options={{
                        enableTime: true,
                        dateFormat: "Y-m-d H:i",
                        time_24hr: false,
                        minDate: "today",
                      }}
                    />
                    <ErrorMessage
                      name="schedule_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Announce For */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="announce_for">
                      Announce For
                    </label>
                    <Field
                      as="select"
                      name="announce_for"
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {announceForOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="announce_for"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Link Text */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="link_text">
                      Link Keyword
                    </label>
                    <Field
                      as="select"
                      name="link_text"
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {linkTextOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="link_text"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Conditionally Render Link URL */}
                  {values.link_text !== "0" && values.link_text && (
                    <div className="mb-3">
                      <label className="form-label" htmlFor="link_url">
                        Link URL
                      </label>
                      <Field
                        type="text"
                        name="link_url"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="link_url"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  )}

                  {/* Image */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="image">
                      Image
                    </label>
                    <input
                      id="image"
                      name="image"
                      type="file"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue("image", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="tw-flex tw-w-full tw-justify-end">
                    <button
                      type="submit"
                      className="primary-btn"
                      disabled={isSubmitting}
                    >
                      Create Announcement
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAnnouncement;
