import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

const SidebarCollapsableItem = ({ sidebarItem, ...props }) => {
  const { title, isCollapsable, heading, links } = sidebarItem;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="sidebar-heading">{title}</div>
      <ul className="sidebar-menu">
        <>
          <li className="sidebar-menu-item open tw-py-4">
            {isCollapsable ? (
              <>
                <button
                  className="btn sidebar-menu-button sidebar-js-collapse"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                  }}
                >
                  <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons ">
                    {heading?.icon}
                  </i>
                  {heading?.title}
                  <span className="ml-auto sidebar-menu-toggle-icon"></span>
                </button>

                {links.length > 0 && (
                  <Collapse in={open} className="">
                    <ul className="">
                      {links?.map((el, elIndex) => (
                        <li className="sidebar-menu-item tw-my-2" key={elIndex}>
                          <Link to={el.link} className="sidebar-menu-button">
                            {el.icon && (
                              <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons tw-size-6">
                                {el.icon}
                              </i>
                            )}
                            {el.title}{" "}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                )}
              </>
            ) : (
              <>
                {links.length > 0 && (
                  <ul className="">
                    {links?.map((el, elIndex) => (
                      <li className="sidebar-menu-item" key={elIndex}>
                        <Link to={el.link} className="sidebar-menu-button">
                          {el.icon && (
                            <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">
                              {el.icon}
                            </i>
                          )}{" "}
                          {el.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </li>
        </>
      </ul>
    </>
  );
};

export default SidebarCollapsableItem;
