import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProfileSection from "../../../Components/ProfileSection";
import { viewSettlementSummaryApi, endTaswiaApi } from "../../../apis";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

const EndSettlement = () => {
  const [settlementData, setSettlementData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id: taswiaId } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    confirm: false,
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    confirm: Yup.boolean().oneOf(
      [true],
      "You must confirm to proceed with the settlement."
    ),
    notes: Yup.string().required("Please provide notes for the settlement."),
  });

  const handleSubmit = async (values) => {
        // Prepare form data
        const formData = {
          notes: values.notes,
          confirm: values.confirm ? 1 : 0, // Send 1 if confirmed
        };
    
    try {
      const response = await endTaswiaApi(
        taswiaId,
        formData.notes,
        formData.confirm
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Success",
          text: "Settlement has been successfully ended.",
          icon: "success",
        });

        navigate(`/settlements`);
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.message.join(", "),
          icon: "error",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message.join(", ") || error.message;
      Swal.fire({
        title: "Error",
        text: `Failed to end settlement: ${errorMessage}`,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await viewSettlementSummaryApi(taswiaId);
        setSettlementData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(
          error.response?.data?.message ||
            "An error occurred while fetching data"
        );
        setLoading(false);
      }
    };
    fetchData();
  }, [taswiaId]);

  if (loading) return <div>Loading...</div>;

  if (error) return <div className="alert alert-danger">{error}</div>;

  if (!settlementData) return <div>No settlement data available</div>;

  const { taswia_data, settlement_summary } = settlementData;
  const { num_of_unsettled_partners, ...closeSettlementData } = taswia_data;
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">End Settlement</h4>

      {/* Display settlement summary details here using ProfileSection or other components */}

      <ProfileSection
        title="Close Settlement"
        data={closeSettlementData}
        columns={3}
      />
      <ProfileSection
        title="Settlement Summary"
        data={settlement_summary}
        columns={3}
      />
      <div className="tw-flex tw-w-full tw-justify-center">
        <div className="tw-bg-red-600/80 tw-text-white tw-p-3 tw-rounded-[10px] tw-mx-4 md:tw-max-w-96 tw-font-semibold tw-text-center">
          No of partners who have not been settled # {num_of_unsettled_partners}
        </div>
      </div>

      {/* <SettlementTableSection data={settlementData.expectedEarnings} /> */}

      <div className="tw-container tw-mx-auto !tw-mb-0 tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <div className="form-group mb-3">
                <label htmlFor="notes">Notes :</label>
                <Field
                  type="text"
                  id="notes"
                  name="notes"
                  className="form-control"
                />
              </div>
              <div className="form-check mb-3">
                <Field
                  type="checkbox"
                  id="confirm"
                  name="confirm"
                  className="form-check-input"
                />
                <label
                  className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                  htmlFor="confirm"
                >
                  I confirm the settlement.
                </label>
                <ErrorMessage
                  name="confirm"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="tw-flex tw-w-full tw-justify-end">
                <button type="submit" className="primary-btn">
                  End Settlement
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EndSettlement;
