import React, { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import { createColumns } from "../../helpers";
import { DATATABLE_CUSTOM_STYLES } from "../../constants/datatableStyles";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import CustomPagination from "../../Components/CustomPagination";
import { viewAdminCoursesApi } from "../../apis";

const ReportDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [requestsData, setRequestsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [courseName, setCourseName] = useState(""); // State to store course name
  const { id: courseId } = useParams();

  useLayoutEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await viewAdminCoursesApi();
        const course = response.data.data.find((course) => {
          return course.id === +courseId;
        });

        if (course) {
          setCourseName(course.course_name_en);
        }
      } catch (error) {
        console.error("Failed to fetch course details", error);
      }
    };

    fetchCourseDetails();
  }, [courseId]);

  useEffect(() => {
    const fetchEnrolledClients = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/admin/reports/courses/enrolled-clients/${courseId}`,
          {
            params: {
              SelectionDateStart: startDate,
              SelectionDateEnd: endDate,
              search: searchTerm,
              page: currentPage,
            },
          }
        );
        setRequestsData(response.data.data);
        setPagination(response.data.paginator);
      } catch (error) {
        console.error("Failed to fetch enrolled clients", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEnrolledClients();
  }, [courseId, startDate, endDate, searchTerm, currentPage]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchTerm) params.set("search", searchTerm);
    if (startDate) params.set("startDate", startDate);
    if (endDate) params.set("endDate", endDate);
    params.set("page", currentPage);
    navigate({ search: params.toString() });
  }, [searchTerm, startDate, endDate, currentPage, navigate]);

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleExport = async (id) => {
    try {
      const response = await axios.get(
        `/admin/reports/courses/export-excel/${id}`,
        {
          params: {
            SelectionDateStart: startDate,
            SelectionDateEnd: endDate,
            search: searchTerm,
          },
          responseType: "blob",
        }
      );

      const currentDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "");
      const fileName = `Enrolled Partners In ${courseName}_${currentDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Failed to export enrolled clients", error);
    }
  };

  const hiddenColumns = ["id", "client_id"];
  const customColumns = [];

  const columns = useMemo(() => {
    return createColumns(requestsData, customColumns, hiddenColumns);
  }, [requestsData]);

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">
          Enrolled Partners in course |{" "}
          <span className="tw-text-red-500">{courseName}</span>
        </h4>
        <div>
          <button
            className="tw-border tw-bg-green-600/20 tw-border-green-500 tw-px-4 tw-py-2 !tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-transition-all tw-duration-200 hover:tw-bg-green-600/30"
            onClick={() => handleExport(courseId)}
          >
            <img src="/imgs/excelIcon.png" className="tw-size-5" alt="" />
            Export
          </button>
        </div>
      </div>

      <div className="bg-white flex flex-col mt-4">
        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <div className="relative tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mb-4 tw-flex-col md:tw-flex-row ">
            <div className="tw-grow tw-w-full">
              <input
                type="text"
                onChange={handleFilter}
                value={searchTerm}
                className="border border-blue-300 rounded-lg p-2 tw-w-full"
                placeholder="Search by name"
              />
            </div>
            <div className="relative tw-flex tw-gap-4 tw-justify-center tw-items-center tw-flex-col md:tw-flex-row  tw-w-full">
              <div className="tw-flex tw-items-center">
                <label className="!tw-m-0 tw-w-[80px] tw-shrink-0">
                  Date From
                </label>
                <Flatpickr
                  value={startDate}
                  onChange={([date]) => setStartDate(date)}
                  className="form-control border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="tw-flex tw-items-center">
                <label className="!tw-m-0 tw-w-[80px] tw-shrink-0">
                  Date To
                </label>
                <Flatpickr
                  value={endDate}
                  onChange={([date]) => setEndDate(date)}
                  className="form-control border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={requestsData}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={pagination?.last_page}
              currentPage={pagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportDetails;
