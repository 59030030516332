import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  saveStepData,
  resendOtpAction,
} from "../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import {
  setStep2IsDisabled,
  setStep2setCounter,
  genericStepDataByKey,
} from "../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import Swal from "sweetalert2";
import * as Yup from "yup";

const OTPVerification = ({
  clientId,
  navigate,
  partnerEmail,
  triggerSendOtp,
}) => {
  const dispatch = useDispatch();
  const { resendOtp: resendOtpInfo, validationObject } = useSelector(
    (state) => state.AddOrEditPartner.step2
  );
  const { isDisabled, counter } = resendOtpInfo;

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        dispatch(setStep2setCounter(counter - 1));
      }, 1000);
    } else {
      dispatch(setStep2IsDisabled(false));
    }

    return () => {
      clearInterval(timer);
    };
  }, [counter, dispatch]);

  useEffect(() => {
    dispatch(setStep2IsDisabled(true));
    dispatch(setStep2setCounter(60));
  }, []);

  useEffect(() => {
    if (triggerSendOtp) {
      resendOTPHandler();
    }
  }, [triggerSendOtp]);

  const stepTwoInitialData = {
    otp: "",
  };

  const SteptwoSchema = Yup.object().shape({
    otp: Yup.string()
      .min(2, "Too Short!")
      .max(6, "Too Long!")
      .required("Required"),
  });

  const handleOTPChange = (e, setFieldValue) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\D/g, "");
    const truncatedValue = sanitizedValue.slice(0, 6);
    setFieldValue(name, truncatedValue);
  };

  const resendOTPHandler = () => {
    Swal.fire({
      title: "Good!",
      text: "please check your Email",
      icon: "success",
    });
    if (!isDisabled) {
      dispatch(
        resendOtpAction(clientId, () => {
          dispatch(setStep2IsDisabled(true));
          dispatch(setStep2setCounter(60));
        })
      );
    }
  };

  const handleStep2Submit = (values) => {
    if (Object.keys(validationObject).length === 0) {
      dispatch(
        saveStepData(
          { ...values, client_id: clientId, is_done: 1 },
          2,
          false,
          () => {
            Swal.fire({
              title: "Good!",
              text: "Email Verified Successfully",
              icon: "success",
            });
            navigate("/partners-list");
          }
        )
      );
    }
  };

  return (
    <div className="card-body">
      <div className="form-group row mt-3">
        <div className="col-sm-3">
          <label className="col-form-label form-label">Partner Email:</label>
        </div>
        <div className="col-sm-6 col-md-4">
          <input
            type="text"
            className={`form-control`}
            value={partnerEmail}
            disabled
          />
        </div>
      </div>
      <Formik
        initialValues={stepTwoInitialData}
        validationSchema={SteptwoSchema}
        onSubmit={handleStep2Submit}
      >
        {({ errors, touched, handleBlur, values, setFieldValue }) => (
          <Form className="d-flex flex-column">
            <div className="form-group row mt-3">
              <div className="col-sm-3">
                <label
                  htmlFor="OTP"
                  className="col-form-label form-label required-label"
                >
                  OTP:
                </label>
              </div>
              <div className="col-sm-6 col-md-4">
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  className={`form-control hidden-controls-number ${
                    touched.otp
                      ? errors.otp || validationObject?.length > 0
                        ? "custom-invalid-input"
                        : ""
                      : ""
                  }`}
                  placeholder="OTP"
                  maxLength="6"
                  onChange={(e) => {
                    handleOTPChange(e, setFieldValue);
                    dispatch(
                      genericStepDataByKey({
                        keyName: "validationObject",
                        keyValue: {},
                        stepNumber: 2,
                      })
                    );
                  }}
                  onBlur={handleBlur}
                  value={values.otp}
                  inputMode="numeric"
                  style={{
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  }}
                />
                {isDisabled ? (
                  <div
                    className="d-block text-right"
                    style={{ fontSize: "12px" }}
                  >
                    {`Wait ${counter} seconds`}
                  </div>
                ) : (
                  <button
                    className="btn d-block text-warning text-right p-0 mr-0"
                    style={{ width: "100%", fontSize: "12px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isDisabled) {
                        resendOTPHandler();
                      }
                    }}
                    disabled={isDisabled}
                  >
                    Re-send OTP Code
                  </button>
                )}
                {touched.otp && (
                  <>
                    {Object.keys(validationObject)?.length > 0
                      ? Object.keys(validationObject)?.map(
                          (validationKey, validationElementIndex) => (
                            <div
                              className="invalid-feedback"
                              key={validationElementIndex}
                            >
                              {validationObject[validationKey]}
                            </div>
                          )
                        )
                      : errors.otp && (
                          <div className="invalid-feedback">{errors.otp}</div>
                        )}
                  </>
                )}
              </div>
            </div>
            <button type="submit" className="btn btn-primary ml-auto">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OTPVerification;
