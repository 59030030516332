import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../Components/Tabs";
import DataTable from "react-data-table-component";
import { createColumns } from "../../helpers";
import { fetchPartners } from "../../Redux/Partners/PartnersActions";
import { DATATABLE_CUSTOM_STYLES } from "../../constants/datatableStyles";
import { Link } from "react-router-dom";
import { PARTNERS_DATA_TABS } from "../../constants/globals";
import { absoluteUrl } from "../../_helper";
import CustomPagination from "../../Components/CustomPagination";

const PartnersList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(location.search).get("tab") || "all"
  );
  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );
  const [sortKey, setSortKey] = useState(
    new URLSearchParams(location.search).get("sortKey") || ""
  );
  const [sortDirection, setSortDirection] = useState(
    new URLSearchParams(location.search).get("sortDirection") || ""
  );

  const { partnersData, isLoading, pagination } = useSelector(
    (state) => state.Partners
  );

  useEffect(() => {
    dispatch(
      fetchPartners(currentTab, searchTerm, currentPage, sortKey, sortDirection)
    );
  }, [currentTab, searchTerm, currentPage, sortKey, sortDirection, dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tab", currentTab);
    if (searchTerm) params.set("search", searchTerm);
    params.set("page", currentPage);
    if (sortKey) params.set("sortKey", sortKey);
    if (sortDirection) params.set("sortDirection", sortDirection);
    navigate({ search: params.toString() });
  }, [currentTab, searchTerm, currentPage, sortKey, sortDirection, navigate]);

  const handleTabClick = (key) => {
    setCurrentTab(key);
    setCurrentPage(1); // Reset to first page on tab change
  };

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortChange = (column, direction) => {
    setSortKey(column.sortKey);
    setSortDirection(direction);
    setCurrentPage(1); // Reset to first page on sort change
  };

  const filterAndSortData = () => {
    return partnersData;
  };

  const hiddenColumns = ["id", "action", "must_verify_email", "client_id"]; // Add column keys you want to hide

  const customColumns =
    currentTab === "all" ||
    currentTab === "pv-active" ||
    currentTab === "direct"
      ? [
          {
            name: "Partner's Tree",
            selector: (row) => row.name + " - custom",
            ui: (row) => (
              <Link to={`/tree/${row.id}`}>
                <img
                  src={absoluteUrl("imgs/tree.png")}
                  style={{ width: 40 }}
                  alt=""
                />
              </Link>
            ),
          },
        ]
      : currentTab === "uncompleted"
      ? [
          {
            name: "Action",
            selector: (row) => row.name + " - uncompleted",
            ui: (row) => (
              <div className="d-flex gap-2">
                <Link
                  to={
                    row.must_verify_email
                      ? `/partners/update/${row.id}/?verifyEmail=true`
                      : `/partners/edit/${row.id}`
                  }
                >
                  {row.action}
                </Link>
                <div className="">|</div>
                <Link to={`/partners/update/${row.id}`} className="ml-2">
                  Edit Step 1
                </Link>
              </div>
            ),
          },
        ]
      : currentTab === "pending"
      ? [
          {
            name: "Action",
            selector: (row) => row.name + " - pending",
            ui: (row) => (
              <div>
                <Link to={`/partners/edit/${row.id}`}>
                  {row.Step_number ? (
                    `Complete Step ${row.Step_number}`
                  ) : (
                    <span className="material-icons">visibility</span>
                  )}
                </Link>
              </div>
            ),
          },
        ]
      : [];

  const columns = useMemo(() => {
    return createColumns(partnersData, customColumns, hiddenColumns, true);
  }, [partnersData]);

  return (
    <div className="bg-white flex flex-col mt-4">
      <Tabs
        options={PARTNERS_DATA_TABS}
        onTabClick={handleTabClick}
        currentTab={currentTab}
      />

      <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
        <div className="relative">
          <input
            type="text"
            onChange={handleFilter}
            value={searchTerm}
            className="border border-blue-300 rounded-lg p-2 mb-2 col-md-12"
            placeholder="Search by name"
          />
        </div>
        <DataTable
          columns={columns}
          data={filterAndSortData()}
          customStyles={DATATABLE_CUSTOM_STYLES}
          progressPending={isLoading}
          onSort={handleSortChange}
          sortServer
        />

        <div className="flex justify-center mt-4">
          <CustomPagination
            totalPages={pagination?.last_page}
            currentPage={pagination?.current_page || 1}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnersList;
