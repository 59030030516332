import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { partnerProfileApi } from "../../apis";
import ProfileSection from "../../Components/ProfileSection";
import CoursesSection from "../../Components/CoursesSection";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    partnerProfileApi(id)
      .then((response) => {
        setProfileData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message || "An error occurred while fetching data");
        setLoading(false);
      });
  }, [id]);

  if (loading) return <div>Loading...</div>;

  if (!profileData) return <div>No profile data available</div>;
  const { image, ...filteredProfileData } = profileData.personal_data;

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <ProfileSection
        title="Personal Data"
        data={filteredProfileData}
        img={image}
      />
      <ProfileSection title="Account Data" data={profileData.account_data} />
      <ProfileSection
        title="Package Data"
        data={profileData.package_data}
        columns={1}
      />
      <CoursesSection courses={profileData.courses_data} />
      <ProfileSection
        title="E-Money Transactions"
        data={profileData.e_money_transactions}
      />
      <ProfileSection title="Wallet Data" data={profileData.wallet_data} />
    </div>
  );
};

export default Profile;
