import axios from "axios";

// Generalized function for fetching partners
function fetchPartners(endpoint, searchTerm = "", page = 1, sortBy, sortDir) {
  return axios.get(`/client/partners-list/${endpoint}`, {
    params: {
      search: searchTerm,
      page,
      sort_by: sortBy,
      sort_dir: sortDir,
    },
  });
}

// Specific functions using the generalized fetchPartners function
export function fetchUncompletedPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchPartners("uncompleted", searchTerm, page, sortBy, sortDir);
}

export function fetchPendingPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchPartners("pending", searchTerm, page, sortBy, sortDir);
}

export const fetchAllPartners = (
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) => {
  return fetchPartners("all", searchTerm, page, sortBy, sortDir);
};

export function fetchPvActivePartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchPartners("pv-active", searchTerm, page, sortBy, sortDir);
}

export function fetchDirectPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchPartners("direct", searchTerm, page, sortBy, sortDir);
}

export function fetchUplinerPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchPartners("upliner", searchTerm, page, sortBy, sortDir);
}
