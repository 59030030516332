import React from "react";
import { AdminIcon } from "./Svgs/AdminIcon";
import { CalenderIcon } from "./Svgs/CalenderIcon";
import moment from "moment/moment";

const AnnouncementCard = ({ announcement }) => {
  const {
    created_by_name: name,
    schedule_date: date,
    title,
    details: description,
    link,
    link_btn_text,
    resource,
  } = announcement;

  const formattedDate = date ? moment(date).format("YYYY-MM-DD hh:mm A") : null;
  const image = resource || "/imgs/orginal-logo.png";
  return (
    <div className="tw-flex tw-bg-white tw-shadow-md w-100 tw-py-4 tw-px-2 tw-border-l-4 tw-border-primary">
      {/* Left Image */}
      {image && (
        <div className="tw-mx-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-size-[150px] ">
          <img
            src={image}
            alt="Announcement"
            className="tw-rounded-lg tw-object-contain tw-size-full"
          />
        </div>
      )}

      {/* Content */}
      <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-items-center tw-mb-2">
          {/* Created By Name */}
          {!!name && (
            <div className="tw-flex tw-items-center">
              <AdminIcon className="tw-text-primary" />
              <span className="tw-ml-2">{name}</span>
            </div>
          )}

          {/* Date */}
          {!!formattedDate && (
            <div className="tw-flex tw-items-center tw-ml-3">
              <CalenderIcon className="tw-text-primary" />
              <span className="tw-ml-2">{formattedDate}</span>
            </div>
          )}

          {/* Link Button */}
          {link && (
            <div className="tw-flex tw-items-center tw-ml-3">
              <span className="fa fa-link tw-text-primary"></span>
              <a href={link} className="tw-ml-2 tw-text-primary tw-underline">
                {link_btn_text || "Join channel"}
              </a>
            </div>
          )}
        </div>

        {/* Title */}
        {title && (
          <div className="tw-mb-2">
            <h3 className="tw-font-semibold tw-text-lg">{title}</h3>
          </div>
        )}

        {/* Description */}
        {description && (
          <div className="tw-text-md tw-text-gray-500">{description}</div>
        )}
      </div>
    </div>
  );
};

export default AnnouncementCard;
