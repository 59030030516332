import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Swal from "sweetalert2";
import {
  updateAnnouncement,
  announcementDetailstApi,
  announceForListApi,
  AnnouncementLinkKeywordApi,
} from "../../../apis"; // Adjust the import path as needed

const validationSchema = Yup.object().shape({
  announcement_title: Yup.string().required("Title is required"),
  announcement_details: Yup.string().required("Details are required"),
  link_url: Yup.string().nullable(),
  on_portal: Yup.string().required("Please select an option for On Portal"),
  schedule_date: Yup.date().required("Schedule date and time are required"),
  announce_for: Yup.string().required("Please select who to announce for"),
  link_text: Yup.string().required("Please select a link text"),
  image: Yup.mixed().nullable(),
});

const AnnouncementEdit = () => {
  const { id } = useParams();
  const [announceForOptions, setAnnounceForOptions] = useState([]);
  const [linkTextOptions, setLinkTextOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [existingImageUrl, setExistingImageUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch options
        const [announceForResponse, linkTextResponse] = await Promise.all([
          announceForListApi(),
          AnnouncementLinkKeywordApi(),
        ]);

        let announceForData = [];
        let linkTextData = [];

        if (announceForResponse.status === 200) {
          announceForData = announceForResponse.data.data;
          // Include default option if necessary
          if (!announceForData.some((option) => option.id === "0")) {
            announceForData.unshift({ id: "0", name: "All Partners" });
          }
          setAnnounceForOptions(announceForData);
        }

        if (linkTextResponse.status === 200) {
          linkTextData = linkTextResponse.data.data;
          // Include default option if necessary
          if (!linkTextData.some((option) => option.id === "0")) {
            linkTextData.unshift({ id: "0", name: "No Link Available" });
          }
          setLinkTextOptions(linkTextData);
        }

        // Now fetch the announcement data
        const announcementResponse = await announcementDetailstApi(id);
        if (announcementResponse.status === 200) {
          const announcement = announcementResponse.data.data;

          // Use IDs directly
          const announce_for_id = announcement.announcement_for_id || "";
          const link_text_id = announcement.announcement_link_keyword_id || "";

          // Map on_portal "Yes"/"No" to "1"/"0"
          const on_portal = announcement.on_portal === "Yes" ? "1" : "0";

          // Parse schedule_date into Date object
          const schedule_date = parseDateTimeString(announcement.schedule_date);

          // Save existing image URL
          setExistingImageUrl(announcement.announcement_image);

          setInitialValues({
            announcement_title: announcement.announcement_title || "",
            announcement_details: announcement.announcement_details || "",
            link_url: announcement.announcement_link || "",
            on_portal: on_portal,
            schedule_date: schedule_date,
            announce_for: announce_for_id,
            link_text: link_text_id,
            image: null, // We'll handle image upload separately
          });
        } else {
          console.error("Failed to fetch announcement data");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Function to parse date-time string into Date object
  const parseDateTimeString = (dateTimeStr) => {
    // Example input: "2024-09-17 05:30:00 PM"
    // Expected output: Date object

    // Split the date and time parts
    const [datePart, timePart, meridiem] = dateTimeStr.split(" ");

    // Parse date part
    const [year, month, day] = datePart.split("-").map(Number);

    // Parse time part
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    // Adjust hours for AM/PM
    if (meridiem === "PM" && hours < 12) {
      hours += 12;
    } else if (meridiem === "AM" && hours === 12) {
      hours = 0;
    }

    // Create Date object
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("announcement_title", values.announcement_title);
      formData.append("announcement_details", values.announcement_details);
      formData.append("link_url", values.link_url || "");
      formData.append("on_portal", values.on_portal);
      formData.append(
        "schedule_date",
        values.schedule_date.toISOString().slice(0, 19).replace("T", " ")
      );
      formData.append("announce_for", values.announce_for);
      formData.append("link_text", values.link_text || "");
      formData.append("_method", "patch");

      if (values.image) {
        formData.append("image", values.image);
      }

      const response = await updateAnnouncement(id, formData);
      Swal.fire({
        title: "Success",
        text: response.data.message || "Announcement updated successfully.",
        icon: "success",
      });

      navigate("/announcements");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update announcement.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading || !initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h4 className="h4 mt-3 mb-5 card-title">Edit Announcement</h4>
      </div>

      <div className="tw-flex-col gap-6">
        <div className="card">
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, isSubmitting, values }) => (
                <Form>
                  {/* Title */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="announcement_title">
                      Title
                    </label>
                    <Field
                      type="text"
                      name="announcement_title"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="announcement_title"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Details */}
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="announcement_details"
                    >
                      Details
                    </label>
                    <Field
                      as="textarea"
                      name="announcement_details"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="announcement_details"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* On Portal */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="on_portal">
                      On Portal
                    </label>
                    <Field
                      as="select"
                      name="on_portal"
                      className="form-control"
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Field>
                    <ErrorMessage
                      name="on_portal"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Schedule Date and Time */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="schedule_date">
                      Schedule Date and Time
                    </label>
                    <Flatpickr
                      id="schedule_date"
                      name="schedule_date"
                      className="form-control"
                      value={values.schedule_date}
                      onChange={(date) => {
                        setFieldValue("schedule_date", date[0]);
                      }}
                      options={{
                        enableTime: true,
                        dateFormat: "Y-m-d H:i",
                        time_24hr: false,
                        minDate: "today",
                      }}
                    />
                    <ErrorMessage
                      name="schedule_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Announce For */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="announce_for">
                      Announce For
                    </label>
                    <Field
                      as="select"
                      name="announce_for"
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {announceForOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="announce_for"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Link Text */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="link_text">
                      Link Keyword
                    </label>
                    <Field
                      as="select"
                      name="link_text"
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {linkTextOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="link_text"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Conditionally Render Link URL */}
                  {values.link_text !== "0" && values.link_text && (
                    <div className="mb-3">
                      <label className="form-label" htmlFor="link_url">
                        Link URL
                      </label>
                      <Field
                        type="text"
                        name="link_url"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="link_url"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  )}

                  {/* Existing Image */}
                  {existingImageUrl && (
                    <div className="mb-3">
                      <label className="form-label">Existing Image:</label>
                      <br />
                      <img
                        src={existingImageUrl}
                        alt="Announcement"
                        style={{ maxWidth: "300px" }}
                      />
                    </div>
                  )}

                  {/* Image */}
                  <div className="mb-3">
                    <label className="form-label" htmlFor="image">
                      Image
                    </label>
                    <input
                      id="image"
                      name="image"
                      type="file"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue("image", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="tw-flex tw-w-full tw-justify-end">
                    <button
                      type="submit"
                      className="primary-btn"
                      disabled={isSubmitting}
                    >
                      Update Announcement
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementEdit;
