import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  replaceEmoneyData: [],
  replaceEmoneyDetails: null,
  isLoading: false,
  error: "", // Error message
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0,
  },
};

export const ReplaceEmoneySlice = createSlice({
  name: "ReplaceEmoney",
  initialState,
  reducers: {
    setReplaceEmoneyData: (state, action) => {
      state.replaceEmoneyData = action.payload.data;
      state.pagination = action.payload.paginator;
    },
    clearReplaceEmoneyData: (state) => {
      state.replaceEmoneyData = [];
      state.pagination = {
        current_page: 1,
        last_page: 1,
        per_page: 15,
        total: 0,
      };
    },
    setReplaceEmoneyDetails: (state, action) => {
      state.replaceEmoneyDetails = action.payload;
    },
    clearReplaceEmoneyDetails: (state) => {
      state.replaceEmoneyDetails = null;
    },
    catchError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = ""; // Clear the error after handling
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setReplaceEmoneyData,
  clearReplaceEmoneyData,
  setReplaceEmoneyDetails,
  clearReplaceEmoneyDetails,
  catchError,
  clearError, // Newly added action to clear the error
  setLoading,
} = ReplaceEmoneySlice.actions;

export default ReplaceEmoneySlice.reducer;
