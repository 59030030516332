import React from "react";

const CoursesSection = ({ courses }) => {

  return (
    <section className="tw-container tw-mx-auto tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md">
      <h3 className="tw-text-xl tw-font-semibold tw-text-red-600 tw-mb-4 tw-border-b tw-pb-2">
        Courses Data
      </h3>
      <div className="tw-overflow-x-auto">
        <table className="tw-min-w-full tw-bg-white tw-rounded-lg tw-shadow-md">
          <thead>
            <tr>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Course Name
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Selection Date
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                E-Money
              </th>
              <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-semibold tw-text-primary tw-uppercase tw-tracking-wider">
                Is Within Package
              </th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course, index) => (
              <tr key={index} className="tw-border-b tw-border-gray-200">
                <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
                  {course.course_name}
                </td>
                <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {course.selection_date}
                </td>
                <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {course.e_money}
                </td>
                <td className="tw-px-4 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-500">
                  {course.is_within_package}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default CoursesSection;
