import React from "react";

const AllMatchedPartners = ({ matchedPartners, onClickHandler, ...props }) => {
  return (
    <>
      {matchedPartners?.length > 0 && (
        <div className="w-100 AllMatchedPartners">
          {matchedPartners?.map((mp, mpI) => (
            <div
              className="w-100 item"
              key={mpI}
              onClick={() => onClickHandler(mp)}
            >
              {mp?.name} <span className="ml-2">({mp?.referral_code})</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AllMatchedPartners;
