import React from "react";

const AnnouncementSection = ({ title, data, isLink }) => (
  <div className="tw-mb-4">
    <h5 className="tw-text-lg tw-text-primary">{title}:</h5>
    {isLink ? (
      <a
        href={data}
        target="_blank"
        rel="noopener noreferrer"
        className="tw-text-blue-500 tw-underline"
      >
        {data}
      </a>
    ) : (
      <p className="tw-text-gray-700">{data}</p>
    )}
  </div>
);

export default AnnouncementSection;
