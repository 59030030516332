import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import FormField from "../../Components/Form/FormField";
import { emailRegex } from "../../constants/globals";
import { partnerProfileApi } from "../../apis";

function reformatPartnerData(originalObject) {
  // Split the full name into parts (assuming it's in the format "first second last")
  const nameParts = originalObject.full_name.split(" ");
  const first_name = nameParts[0];
  const second_name = nameParts.length > 1 ? nameParts[1] : "";
  const last_name = nameParts.length > 2 ? nameParts[2] : "";
  const phone = originalObject.phone_1.replace(/\+2\s*/g, "").trim();
  // Create the new object
  const reformattedObject = {
    first_name: first_name,
    second_name: second_name,
    last_name: last_name,
    phone: phone,
    email: originalObject.email,
    identification_type:
      originalObject.identification_type === "Passport" ? "2" : "1",
    identification_number: originalObject.identification_number,
    is_done: 1,
  };
  return reformattedObject;
}
const UpdatePartnerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [partnerData, setPartnerData] = useState(null);
  const [nextStep, setNextStep] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);

  useEffect(() => {
    partnerProfileApi(id).then((response) => {
      setPartnerData(reformatPartnerData(response?.data?.data?.personal_data));
    });
  }, [id]);

  useEffect(() => {
    if (partnerData?.account_creation_status_id === 7) {
      navigate("/partners-list");
    }
  }, [partnerData, navigate]);

  const initialValues = {
    first_name: partnerData?.first_name || "",
    second_name: partnerData?.second_name || "",
    last_name: partnerData?.last_name || "",
    email: partnerData?.email || "",
    phone: partnerData?.phone || "",
    phone_country_code: "+2",
    identification_type: partnerData?.identification_type || "1",
    identification_number: partnerData?.identification_number || "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    second_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^(010|011|012|015)\d{8}$/, {
        message:
          "Phone number must be 11 digits and start with 010, 011, 012, or 015",
        excludeEmptyString: true,
      }),
    phone_country_code: Yup.string().required("Country code is required"),
    identification_type: Yup.string().required("Required"),
    identification_number: Yup.string()
      .test(
        "identification-number-validation",
        "Invalid Identification Number",
        function (value) {
          const { identification_type } = this.parent;
          if (identification_type === "1") {
            return /^\d{14}$/.test(value);
          } else {
            return /^[a-zA-Z]?\d{8}$/.test(value);
          }
        }
      )
      .required("Required"),
  });

  const handleSubmit = (
    values,
    { setSubmitting, validateForm, setTouched }
  ) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        // No validation errors, proceed with submission
        axios
          .post(`/admin/partners-list/step1-update-client-profile`, {
            ...values,
            is_done: 1,
            client_id: id,
          })
          .then((response) => {
            Swal.fire({
              title: "Good!",
              text: "Profile Updated Successfully",
              icon: "success",
            });
            navigate("/partners-list");
          })
          .catch((error) => {
            if (error) {
              setServerErrors(error.response.data.message);
            } else {
              console.error("Error updating step data:", error);
            }
          });
      } else {
        // There are validation errors, mark all fields as touched
        setTouched(errors);
      }
      setSubmitting(false);
    });
  };

  if (!partnerData) return <div>No profile data available</div>;
  return (
    <div className="card">
      <div className="card-body">
        {serverErrors.length > 0 && (
          <div className="alert alert-danger">
            {serverErrors.map((item, index) => (
              <div key={index}>
                <p className="!tw-mb-0">{item}</p>
              </div>
            ))}
          </div>
        )}
        <div className="card-header">
          <h4 className="card-title mb-0">Update Partner Profile</h4>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            values,
            setFieldValue,
          }) => (
            <Form className="d-flex flex-column">
              <div className="row">
                <FormField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.first_name}
                />
                <FormField
                  name="second_name"
                  label="Second Name"
                  placeholder="Second Name"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.second_name}
                />
                <FormField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.last_name}
                />
                <FormField
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone"
                  type="text"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.phone}
                />
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.email}
                />
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="identification_type"
                  >
                    Identification Type
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.identification_type && errors.identification_type
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="identification_type"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("identification_type", e.target.value);
                      setFieldValue("identification_number", "");
                    }}
                    disabled={true}
                  >
                    <option value="1">National ID</option>
                    <option value="2">Passport</option>
                  </Field>
                  {touched.identification_type &&
                    errors.identification_type && (
                      <div className="invalid-feedback">
                        {errors.identification_type}
                      </div>
                    )}
                </div>
                <FormField
                  name="identification_number"
                  label="Identification Number"
                  placeholder="Identification Number"
                  type="text"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (values.identification_type === "1") {
                      if (/^\d{0,14}$/.test(value)) {
                        setFieldValue("identification_number", value);
                      }
                    } else {
                      if (/^[a-zA-Z]?\d{0,8}$/.test(value)) {
                        setFieldValue("identification_number", value);
                      }
                    }
                  }}
                  value={values.identification_number}
                />
              </div>
              <button type="submit" className="btn btn-primary ml-auto">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdatePartnerProfile;
