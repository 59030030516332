import * as requestFromServer from "./AdminPartnersCrud";
import { AdminPartnersSlice } from "./AdminPartnersSlice";
import { UISlice } from "../UISlice";

const { actions: AdminPartnersActions } = AdminPartnersSlice;
const { actions: UIActions } = UISlice;

export const fetchAdminPartners =
  (type, searchTerm = "", page = 1, sortBy = "", sortDir = "asc") =>
  (dispatch) => {
    dispatch(UIActions.openLoader());

    let fetchFunction;
    switch (type) {
      case "uncompleted":
        fetchFunction = requestFromServer.fetchAdminUncompletedPartners;
        break;
      case "pending":
        fetchFunction = requestFromServer.fetchAdminPendingPartners;
        break;
      case "pv-active":
        fetchFunction = requestFromServer.fetchAdminPvActivePartners;
        break;
      case "direct":
        fetchFunction = requestFromServer.fetchAdminDirectPartners;
        break;
      case "upliner":
        fetchFunction = requestFromServer.fetchAdminUplinerPartners;
        break;
      case "all":
      default:
        fetchFunction = requestFromServer.fetchAdminAllPartners;
        break;
    }

    return fetchFunction(searchTerm, page, sortBy, sortDir)
      .then((response) => {
        if (response.data.status) {
          dispatch(
            AdminPartnersActions.setAdminPartnersData({
              data: response.data.data,
              paginator: response.data.paginator,
            })
          );
        } else {
          dispatch(
            AdminPartnersActions.setAdminPartnersData({
              data: [],
              paginator: {},
            })
          );
        }
      })
      .catch((error) => {
        dispatch(AdminPartnersActions.catchAdminError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };
