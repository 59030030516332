import axios from "axios";

export function fetchPendingRequests(searchTerm = "", page = 1) {
  return axios.get(`/admin/payment/requests/pending`, {
    params: { search: searchTerm, page },
  });
}

export function fetchAcceptedRequests(searchTerm = "", page = 1) {
  return axios.get(`/admin/payment/requests/accepted`, {
    params: { search: searchTerm, page },
  });
}

export function fetchRejectedRequests(searchTerm = "", page = 1) {
  return axios.get(`/admin/payment/requests/rejected`, {
    params: { search: searchTerm, page },
  });
}

export function viewPaymentVerification(id) {
  return axios.get(`/admin/payment/requests/pending-details/${id}`);
}

export function verifyClientPayment(data) {
  return axios.post(`/admin/payment/requests/verify-client-payment`, data);
}
