const formatSortKey = (key) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

export const createColumns = (
  data = [],
  customColumns = [],
  hiddenColumns = [],
  renderImage = false,
  keyTransformer = (key) => key // Default identity function
) => {
  if (!data || data.length === 0) {
    return [];
  }

  const keys = Object.keys(data[0]);

  const defaultColumns = keys
    .filter((key) => !hiddenColumns.includes(key))
    .map((key) => {
      // Transform the key using the custom function
      const transformedKey = keyTransformer(key);

      const name = transformedKey
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());

      const sortKey = formatSortKey(transformedKey);

      return {
        name: name,
        key: key, // Add original key
        transformedKey: transformedKey, // Add transformed key
        selector: (row) => row[key],
        sortKey: sortKey,
        sortable: true,
        wrap: true,
        cell:
          (renderImage && key === "image") || key === "profile_pic"
            ? (row) => (
                <img
                  src={row.image || "/imgs/partner.png"}
                  alt={row.name || ""}
                  className="tw-rounded-full tw-border-2 tw-w-10 tw-h-10 tw-shrink-0"
                />
              )
            : undefined,
      };
    });

  const columns = [
    ...defaultColumns,
    ...customColumns.map(({ name, selector, ui }) => ({
      name,
      selector,
      sortable: false,
      wrap: true,
      cell: ui ? (row) => ui(row) : undefined,
    })),
  ];

  return columns;
};
