export const USER_TYPES = {
  ADMIN: 1,
  MODERATOR: 2,
  PARTNER: 3,
};

export const PARTNERS_DATA_TABS = [
  { key: "all", label: "All Partners" },
  { key: "pv-active", label: "Active" },
  { key: "direct", label: "Directs" },
  // { key: "upliner", label: "Upliners" },
  { key: "pending", label: "Pending" },
  { key: "uncompleted", label: "Uncompleted" },
];
export const ADMIN_PARTNERS_DATA_TABS = [
  { key: "all", label: "All Partners" },
  { key: "pv-active", label: "Active" },
  { key: "pending", label: "Pending" },
  { key: "uncompleted", label: "Uncompleted" },
];
export const ADMIN_DATA_TABS = [
  { key: "pending", label: "Pending" },
  { key: "accepted", label: "Accepted" },
  { key: "rejected", label: "Rejected" },
];
export const MODERATOR_DATA_TABS = [
  { key: "accepted", label: "Accepted" },
  { key: "rejected", label: "Rejected" },
];


export const DEFAULT_TREE_LEVEL = 3;

export const emailRegex = /^\S+@\S+\.\S+$/;
