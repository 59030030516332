import React, { useState } from "react";
import { absoluteUrl } from "../_helper";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toggleSidebarCollapse } from "../Redux/UISlice";
import { logoutAction } from "../Redux/Auth/AuthActions";

const Header = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Getting User Data from Redux Store
  const { loggedInUser } = useSelector((state) => state.Auth);
  const { sales_link } = loggedInUser?.data?.model?.client || {};

  const copyToClipboard = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Link copied to clipboard!",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed to copy the link: ",
          err,
        });
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div
      id="header"
      data-fixed=""
      className="mdk-header js-mdk-header mb-0 w-100"
    >
      <div className="mdk-header__content">
        <nav
          id="default-navbar"
          className="navbar navbar-expand navbar-dark bg-primary m-0"
        >
          <div className="container-fluid">
            <button
              onClick={() => dispatch(toggleSidebarCollapse())}
              className="navbar-toggler d-block"
              data-toggle="sidebar"
              type="button"
            >
              <span className="material-icons">menu</span>
            </button>

            <Link className="navbar-brand" to={"/"}>
              <img
                src={absoluteUrl("imgs/logo-white.png")}
                className="mr-2 header-logo"
                alt="Win Life"
              />
            </Link>

            <div className="navbar-brand ml-5 d-flex align-items-center flex-column my-2">
              {sales_link && (
                <h3 className="c-white mb-0 mr-auto tw-hidden lg:tw-block">
                  Sale Link: <span className="ml-2">{sales_link}</span>
                  <i
                    className="ml-2 fa fa-copy fa-1x"
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(sales_link)}
                  ></i>
                </h3>
              )}
            </div>

            <div className="flex"></div>

            <ul className="nav navbar-nav flex-nowrap d-none d-lg-flex">
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => dispatch(logoutAction())}
                >
                  Logout
                </button>
              </li>
            </ul>

            <ul className="nav navbar-nav flex-nowrap">
              <li className="nav-item dropdown ml-1 ml-md-3">
                <div className="tw-hidden lg:tw-block">
                  <span className="c-white mr-3">
                    {loggedInUser?.data?.model?.first_name}{" "}
                    {loggedInUser?.data?.model?.second_name}{" "}
                    {loggedInUser?.data?.model?.last_name}
                  </span>
                </div>
                <div className="tw-flex lg:tw-hidden tw-text-white tw-mr-3">
                  Hi {loggedInUser?.data?.model?.first_name}
                </div>
                <div className={`dropdown ${isDropdownOpen ? "show" : ""}`}>
                  <img
                    src={absoluteUrl("imgs/partner.png")}
                    alt="Avatar"
                    className="rounded-circle"
                    width="40"
                    onClick={toggleDropdown}
                    style={{ cursor: "pointer" }}
                  />
                  <div
                    className={`dropdown-menu dropdown-menu-right ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => dispatch(logoutAction())}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
