import React from 'react'
import AuthPagesTemplate from '../Pages/Auth/AuthPagesTemplate'
import { Outlet } from 'react-router-dom'

const NotLoggedInLayout = () => {
    return (
        <AuthPagesTemplate>
            <Outlet />
        </AuthPagesTemplate>
    )
}

export default NotLoggedInLayout
