import React, { Suspense } from "react";
import "./Sass/App.scss";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

// Importing JS libraries
// import 'jquery';
import "popper.js";
import "bootstrap";
import "perfect-scrollbar";
import "highlight.js/lib/core";
import AuthenticatedRoutes from "./Routes/AuthenticatedRoutes";
import NotAuthinticatedRoutes from "./Routes/NotAuthinticatedRoutes";
import Loader from "./Components/Loader";
import { useSelector } from "react-redux";

function App() {
  // const authinticated = true
  const { loading } = useSelector((state) => state?.UI);
  const { loggedInUser } = useSelector((state) => state?.Auth);

  return (
    <>
      {loading && <Loader />}
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {Object.keys(loggedInUser).length > 0 ? (
            <AuthenticatedRoutes />
          ) : (
            <NotAuthinticatedRoutes />
          )}
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
