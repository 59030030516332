import React from "react";

const RequestHeader = ({ requestDetails, title = "Request Details" }) => (
  <div className="">
    <h2 className="mb-4 !tw-text-red-600 tw-text-2xl">{title}</h2>
    <div className="request-header">
      {requestDetails.request_number && (
        <p>
          Request No: <span>{requestDetails.request_number}</span>
        </p>
      )}
      <p>
        Request Date:{" "}
        <span>{new Date(requestDetails.request_date).toLocaleString()}</span>
      </p>
      <p>
        Request Status: <span>{requestDetails.request_status}</span>
      </p>
    </div>
  </div>
);

export default RequestHeader;
