import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  viewReplaceEmoneyDetails,
  acceptReplaceEmoneyRequest,
  rejectReplaceEmoneyRequest,
} from "../../../Redux/ReplaceEmoney/ReplaceEmoneyActions";
import RequestSection from "../../../Components/RequestDetails/RequestSection";
import RequestHeader from "../../../Components/RequestDetails/RequestHeader";
import RequestActions from "../../../Components/RequestDetails/RequestActions";
import ReplaceAcceptModal from "../../../Components/ReplaceDetails/ReplaceAcceptModal";
import ReplaceRejectModal from "../../../Components/ReplaceDetails/ReplaceRejectModal";
import { earningWalletApi } from "../../../apis";
import Swal from "sweetalert2";
import { clearError } from "../../../Redux/ReplaceEmoney/ReplaceEmoneySlice"; // Import clearError action

Modal.setAppElement("#root");

const ReplaceEmoneyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { replaceEmoneyDetails, isLoading, error } = useSelector(
    (state) => state.ReplaceEmoney
  );

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [eMoney, setEMoney] = useState(0);
  const [acceptanceNote, setAcceptanceNote] = useState("");
  const [rejectionNote, setRejectionNote] = useState("");
  const [userWallet, setUserWallet] = useState();

  const fetchUserWallet = async () => {
    try {
      const response = await earningWalletApi(replaceEmoneyDetails.partner_id);
      setUserWallet(response.data.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to fetch user wallet. Please try again later.",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    dispatch(viewReplaceEmoneyDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (replaceEmoneyDetails?.actual_money) {
      setEMoney(replaceEmoneyDetails.actual_money);
    }
  }, [replaceEmoneyDetails]);

  useEffect(() => {
    if (replaceEmoneyDetails?.partner_id) {
      fetchUserWallet();
    }
  }, [replaceEmoneyDetails?.partner_id]);

  const onSuccessHandler = () => {
    navigate("/replace-emoney");
    Swal.fire({
      title: "Good!",
      text: "Transaction has been done successfully",
      icon: "success",
    });
    setIsAcceptModalOpen(false); // Close modal after success
  };

  const onSuccessHandler2 = () => {
    navigate("/replace-emoney");
    Swal.fire({
      title: "Done!",
      text: "Request has been rejected successfully",
      icon: "success",
    });
    setIsRejectModalOpen(false); // Close modal after success
  };

  const handleVerifyReplaceEmoneyRequest = (actionType) => {
    if (actionType === "accept") {
      dispatch(
        acceptReplaceEmoneyRequest(id, acceptanceNote, onSuccessHandler)
      );
    } else {
      dispatch(
        rejectReplaceEmoneyRequest(id, rejectionNote, onSuccessHandler2)
      );
    }
  };

  // Show error with Swal when there is an error from Redux
  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error!",
        text: error, // Show the error message from Redux
        icon: "error",
      });
      dispatch(clearError()); // Clear the error after showing it
    }
  }, [error, dispatch]); // Trigger Swal when 'error' changes

  if (isLoading || !replaceEmoneyDetails) {
    return <div>Loading...</div>;
  }

  const userDetails = {
    ...replaceEmoneyDetails.partner_data,
    wallet: userWallet,
  };

  return (
    <div className="request-details">
      <RequestHeader
        requestDetails={replaceEmoneyDetails}
        title="Replace E-Money Request Details"
      />
      <div className="request-body">
        <div className="row flex-grow-1">
          <div className="col-md-6">
            <RequestSection
              title="Partner Data"
              data={replaceEmoneyDetails.partner_data}
            />

            <RequestSection
              title="Request Value"
              data={
                `${replaceEmoneyDetails.emoney} e.m` || "No notes available"
              }
            />
            <RequestSection
              title="Partner Notes"
              data={replaceEmoneyDetails.partner_notes || "No notes available"}
            />
            <RequestSection
              title={`Admin Notes`}
              data={
                (replaceEmoneyDetails.request_status === "Accepted"
                  ? replaceEmoneyDetails.acceptance_note
                  : replaceEmoneyDetails.rejection_note) || "No notes available"
              }
            />
          </div>
        </div>
      </div>
      {replaceEmoneyDetails.request_status.toLowerCase() === "pending" && (
        <RequestActions
          onAccept={() => setIsAcceptModalOpen(true)}
          onReject={() => setIsRejectModalOpen(true)}
        />
      )}
      <ReplaceAcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        handleVerifyPayment={() => handleVerifyReplaceEmoneyRequest("accept")}
        eMoney={eMoney}
        setEMoney={setEMoney}
        acceptanceNote={acceptanceNote}
        setAcceptanceNote={setAcceptanceNote}
        requestDetails={replaceEmoneyDetails}
        userDetails={userDetails}
        actualMoney={replaceEmoneyDetails.actual_money}
      />
      <ReplaceRejectModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        handleVerifyPayment={() => handleVerifyReplaceEmoneyRequest("reject")}
        rejectionNote={rejectionNote}
        setRejectionNote={setRejectionNote}
        requestDetails={replaceEmoneyDetails}
        userDetails={userDetails}
      />
    </div>
  );
};

export default ReplaceEmoneyDetails;
