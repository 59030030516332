import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: "",
  forgetPasswordInfo: {},
  loggedInUser: {},
  walletBalance: 0,
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload;
    },
    setLoggedInUserInfo: (state, action) => {
      state.loggedInUser = { ...action.payload };
    },
    clearLoggedInUser: (state, action) => {
      localStorage.clear();
      sessionStorage.clear();
      return { ...initialState };
    },
    setForgetPasswordInfo: (state, action) => {
      const { email, type, token } = action.payload;
      state.forgetPasswordInfo = {
        ...state.forgetPasswordInfo,
        email,
        type,
        token,
      };
    },
    setWalletBalance: (state, action) => {
      state.walletBalance = action.payload;
    },
  },
});

export const { clearLoggedInUser, setWalletBalance } = AuthSlice.actions;
export default AuthSlice.reducer;
