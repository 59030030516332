import axios from "axios";

export function pendingEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/pending`, {
    params: { search: searchTerm, page },
  });
}

export function acceptedEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/accepted`, {
    params: { search: searchTerm, page },
  });
}

export function rejectedEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/rejected`, {
    params: { search: searchTerm, page },
  });
}

export const viewEMoneyDetailsApi = (id) => {
  return axios.get(`/admin/replace/emoney/details/${id}`);
};

export const acceptEMoneyRequestApi = (id, data) => {
  return axios.post(`/admin/replace/emoney/accept/${id}`, data);
};

export const rejectEMoneyRequestApi = (id, data) => {
  return axios.post(`/admin/replace/emoney/reject/${id}`, data);
};
