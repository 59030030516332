import React from "react";
import { formatKey } from "../_helper";
import PaymentDocument from "./RequestDetails/PaymentDocument";
import { Link } from "react-router-dom";

const ProfileSection = ({
  title,
  data,
  columns = 2,
  img,
  link,
  className = "tw-container tw-mx-auto tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md tw-relative",
}) => {
  const gridColumnsClass =
    columns === 1
      ? "tw-grid-cols-1"
      : columns === 2
      ? "tw-grid-cols-2"
      : columns === 3
      ? "tw-grid-cols-3"
      : `tw-grid-cols-${columns}`;

  return (
    <section className={className}>
      {img !== undefined && (
        <div className="tw-absolute tw-size-10 lg:tw-size-20 tw-top-4 tw-rounded-full tw-right-10 tw-bg-white tw-shadow-lg tw-border-2 tw-p-2 tw-border-primary/5">
          <img
            src={img || "/imgs/partner.png"}
            alt="Profile"
            className="tw-size-full tw-rounded-full tw-object-cover"
          />
        </div>
      )}
      <h3 className="tw-text-xl tw-font-semibold tw-text-red-600 tw-mb-4 tw-border-b tw-pb-2">
        {title}
      </h3>
      <div className={`tw-grid ${gridColumnsClass} tw-gap-2 tw-text-gray-700`}>
        {data && Array.isArray(data)
          ? data.map((item, index) => (
              <div key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <div key={key}>
                    {key === "payment_document" ? (
                      <PaymentDocument documentUrl={value} />
                    ) : (
                      <p>
                        <strong className="tw-text-primary">
                          {formatKey(key)}:
                        </strong>{" "}
                        {value}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            ))
          : Object.entries(data).map(([key, value]) => (
              <div key={key}>
                {key === "payment_document" ? (
                  <PaymentDocument documentUrl={value} />
                ) : (
                  <p>
                    <strong className="tw-text-primary">
                      {formatKey(key)}:
                    </strong>{" "}
                    {value}
                  </p>
                )}
              </div>
            ))}
      </div>
      {link && (
        <div className="tw-mt-4 tw-flex tw-justify-end">
          <div className="primary-btn cursor-pointer">
            <Link className="text-white text-decoration-none" to={link.url}>
              {link.title}
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProfileSection;
