import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { manageWallet, searchClientInWalletApi } from "../../apis";
import { useNavigate } from "react-router-dom";

const WalletManagement = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  // Schema for form validation using Yup
  const validationSchema = Yup.object().shape({
    action_type: Yup.string().required("Please select an action"),
    client_id: Yup.string().required("Please select a partner"),
    wallet_type: Yup.string().required("Please select a wallet type"),
    amount: Yup.number()
      .required("Please enter an amount")
      .min(1, "Amount must be greater than 0"),
    reason: Yup.string().required("Please provide a reason"),
    confirm: Yup.bool().oneOf([true], "You must confirm the information"),
  });

  // Function to handle client search
  const handleSearchClient = async (value) => {
    try {
      const response = await searchClientInWalletApi(value);
      if (response.status === 200) {
        setSearchResults(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching client:", error);
    }
  };

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await manageWallet(values);
      Swal.fire({
        title: "Success",
        text: "Wallet action completed successfully.",
        icon: "success",
      });
      resetForm();
      navigate("/wallet-management");
    } catch (error) {
      Swal.fire({
        title: "Error",
        text:
          error.response?.data?.message ||
          "Failed to complete the wallet action.",
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="wallet-management">
      <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Wallet Management</h4>
      <Formik
        initialValues={{
          action_type: "",
          client_id: "",
          wallet_type: "",
          amount: "",
          reason: "",
          confirm: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isSubmitting, touched, errors }) => (
          <Form>
            {/* Action Type (Create or Withdraw) */}
            <div className="mb-3 tw-flex tw-flex-col tw-gap-6">
              <label className="form-label">Action</label>
              <div className="tw-flex gap-4">
                <div className="form-check">
                  <Field
                    type="radio"
                    name="action_type"
                    value="create"
                    className="form-check-input"
                    id="create"
                  />
                  <label for="create" className="form-check-label !tw-mt-0">
                    Create
                  </label>
                </div>
                <div className="form-check tw-flex tw-items-center">
                  <Field
                    type="radio"
                    name="action_type"
                    value="withdraw"
                    className="form-check-input"
                    id="withdraw"
                  />
                  <label for="withdraw" className="form-check-label !tw-mt-0">
                    Withdraw
                  </label>
                </div>
              </div>
              <ErrorMessage
                name="action_type"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {/* Select Partner */}
            <div className="mb-3">
              <label className="form-label">Select Partner</label>
              <Field
                type="text"
                name="client_id"
                className={`form-control ${
                  touched.client_id && errors.client_id ? "is-invalid" : ""
                }`}
                placeholder="Search partner"
                value={selectedClient}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedClient(value);
                  handleSearchClient(value);
                  setFieldValue("client_id", "", true);
                }}
              />
              <ErrorMessage
                name="client_id"
                component="div"
                className="invalid-feedback"
              />
              {searchResults.length > 0 && (
                <ul
                  ref={dropdownRef}
                  className="dropdown-results tw-h-[200px] tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-w-full tw-mt-2 tw-max-h-48 tw-overflow-y-auto tw-z-10 "
                >
                  {searchResults.map((result) => (
                    <li key={result.id}>
                      <button
                        type="button"
                        className="tw-w-full tw-text-left tw-px-4 tw-py-2 tw-cursor-pointer tw-hover:bg-gray-100"
                        onClick={() => {
                          setFieldValue("client_id", result.id);
                          setSelectedClient(result.name);
                          setSearchResults([]);
                        }}
                      >
                        {result.name} ({result.referral_code})
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Select Wallet Type */}
            <div className="mb-3">
              <label className="form-label">Select Wallet Type</label>
              <Field as="select" name="wallet_type" className="form-control">
                <option value="">Select Wallet Type</option>
                <option value="1">Registration Wallet</option>
                <option value="2">Earning Wallet</option>
                <option value="3">Points Wallet</option>
              </Field>
              <ErrorMessage
                name="wallet_type"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {/* Added Value */}
            <div className="mb-3">
              <label className="form-label">Amount</label>
              <Field
                type="number"
                name="amount"
                className={`form-control ${
                  touched.amount && errors.amount ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="amount"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {/* Reason */}
            <div className="mb-3">
              <label className="form-label">Reason</label>
              <Field as="textarea" name="reason" className="form-control" />
              <ErrorMessage
                name="reason"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {/* Confirmation Checkbox */}
            <div className="mb-3 form-check tw-flex tw-flex-col tw-justify-center">
              <Field
                type="checkbox"
                name="confirm"
                className="form-check-input"
                id="confirm"
              />
              <label className="form-check-label" for="confirm">
                I confirm the provided data is correct.
              </label>
              <ErrorMessage
                name="confirm"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {/* Submit Button */}
            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WalletManagement;
